import { apiProvider } from "../data/Api";

const processData = async (newData, outputs, setOutputs) => {
    let res = await apiProvider.post('calc', 'getData', newData);
    setOutputs(prev => {
        prev.kpis = res[0];
        return ({...prev});
    });

    res = await apiProvider.post('calc', 'getCosts', newData);
    let costs = {};
    costs.tax_raised_year_25 = res[0].tax_raised_year_25;
    costs.tax_raised_year_10 = res[0].tax_raised_year_10;
    costs.yearly_costs = [];
    Object.keys(res[0]).forEach(year => {
        if(year === 'tax_raised_year_25' || year === 'tax_raised_year_10') return;
        costs.yearly_costs.push(res[0][year]);
    });
    res = await apiProvider.post('calc', 'getInstalls', newData);
    costs.yearly_installs = [];
    Object.keys(res[0]).forEach(year => {
        costs.yearly_installs.push(res[0][year]);
    });
    setOutputs(prev => {
        prev.costs = costs;
        return ({...prev});
    });
    
    res = await apiProvider.post('calc', 'pwc', newData);
    setOutputs(prev => {
        prev.pwc = res[0];
        return ({...prev});
    })

    return outputs;
};

export const dataUpdates = {
    processData
}