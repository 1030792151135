import React, { useContext, useCallback, useMemo } from 'react';
import SliderInput from './Common/Inputs/slider.component';
import RadioGroupInput from './Common/Inputs/radioGroup.component';
import { DataContext } from '../context/DataContext';
import { StructureContext } from '../context/StructureContext';
import { OutputContext } from '../context/OutputContext';
import { dataUpdates } from '../utils/processData';
import Separator from './Layout/separator.component';
import debounce from 'lodash/debounce';

const Measures = () => {
    const [data, setData] = useContext(DataContext);
    const [structure] = useContext(StructureContext);
    const [outputs, setOutputs] = useContext(OutputContext);

    const sendRequest = useCallback((update, outputs, setOutputs) => {
        dataUpdates.processData(update, outputs, setOutputs);
    }, []);

    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 300);
    }, [sendRequest]);

    let sliderHandler = name => (event, newValue) => {
        let update = data;
        update.measuresValues[name] = newValue;
        setData(prev => {
            prev = update;
            return ({ ...prev });
        });

        debouncedSendRequest(update, outputs, setOutputs);
    };

    let heatingHandler = (event) => {
        let uptake_hp = '';
        let uptake_olch = '';
        switch (event.target.value) {
            case 'hp_10_olch_90':
                uptake_hp = 10;
                uptake_olch = 90;
                break;
            case 'hp_50_olch_50':
                uptake_hp = 50;
                uptake_olch = 50;
                break;
            case 'hp_70_olch_30':
                uptake_hp = 70;
                uptake_olch = 30;
                break;
            case 'hp_80_olch_20':
                uptake_hp = 80;
                uptake_olch = 20;
                break;
            case 'hp_90_olch_10':
                uptake_hp = 90;
                uptake_olch = 10;
                break;
            case 'hp_0_olch_0':
                uptake_hp = 0;
                uptake_olch = 0;
                break;
            default:
                console.log("Incorrect hp ratio");
        }

        let update = data;
        update.hpRatio = event.target.value;
        update.measuresValues.uptake_hp = uptake_hp;
        update.measuresValues.uptake_olch = uptake_olch;
        setData(prev => {
            prev = update;
            return ({ ...prev });
        });

        dataUpdates.processData(update, outputs, setOutputs);
    };

    let savingsUpdate = (event) => {
        let update = data;
        update.savingsAssumption = event.target.value;
        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
        
        dataUpdates.processData(update, outputs, setOutputs);
    };

    let scopUpdate = (event) => {
        let update = data;
        update.scopAssumption = event.target.value;
        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
        
        dataUpdates.processData(update, outputs, setOutputs);
    }

    return (
        <div className="container-content flex-card">
            <div>
                For each retrofit measure, you can use the sliders to adjust your level of ambition i.e. the percentage of suitable roofs or walls that you think should have solar panels or insulation. For example, if you select 100% for cavity wall insulation, then all homes with unfilled cavity walls that are suitable will be filled. More details here [1].
            </div>
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Insulation measures</div>
                <div className='grid max-width-800'>
                    {
                        outputs.kpis && structure.measuresList.map(measure => {
                            if (measure.group === 'insulation') return (
                                <SliderInput
                                    name={measure.name}
                                    label={measure.label}
                                    disabled={false}
                                    value={data.measuresValues[measure.name]}
                                    quantity={outputs.kpis[measure.name]}
                                    handler={sliderHandler}
                                    key={measure.name}
                                    tooltip={measure.tooltip}
                                />
                            );
                            else return null
                        })
                    }
                </div>
            </div>
            <Separator edge='left' />
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Low carbon solutions</div>
                <div className='grid max-width-800'>
                    {
                        outputs.kpis && structure.measuresList.map(measure => {
                            if (measure.group === 'low_carbon') return (
                                <SliderInput
                                    name={measure.name}
                                    label={measure.label}
                                    disabled={false}
                                    value={data.measuresValues[measure.name]}
                                    quantity={outputs.kpis[measure.name]}
                                    handler={sliderHandler}
                                    key={measure.name}
                                    tooltip={measure.tooltip}
                                />
                            );
                            else return null
                        })
                    }
                </div>
            </div>
            <Separator edge='right' />
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Other measures</div>
                <div className='grid max-width-800'>
                    {
                        outputs.kpis && structure.measuresList.map(measure => {
                            if (measure.group === 'other') return (
                                <SliderInput
                                    name={measure.name}
                                    label={measure.label}
                                    disabled={false}
                                    value={data.measuresValues[measure.name]}
                                    quantity={outputs.kpis[measure.name]}
                                    handler={sliderHandler}
                                    key={measure.name}
                                    tooltip={measure.tooltip}
                                />
                            );
                            else return null
                        })
                    }
                </div>
            </div>
            <Separator edge='left' />
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Heating measures</div>
                <div className='text-medium'>Select the ratio of heating systems to be installed. Gas systems assumed to be completely replaced.</div>
                <RadioGroupInput
                    value={data.hpRatio} options={structure.heatingOptions} handleChange={heatingHandler} key='heating'
                    tooltip={'Other low carbon heating includes: electric storage heaters, electric radiators, bio fuels, bio gas, wood chips etc including these technologies on district heating schemes. For calculation purposes 100% efficiency is assumed and electric costs.Heat pumps assume a SCOP of 2.7 and include all types of heat pumps. Including heat pumps on district heating systems.The marginal, additional, cost of low carbon heating system is used on the assumption the existing heating system being replaced was at end of life.'}
                />
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Heat pump SCOP assumption</div>
                <div className='text-medium'>Select the seasonal performance of the heat pump. A SCOP of 2.7 is the higher performance, for each unit of electricity 2.7 units of heat are produced, an efficiency of 270%.</div>
                <RadioGroupInput
                    value={data.scopAssumption} options={structure.scopOptions} handleChange={scopUpdate} key='scop'
                />
            </div>
            </div>
            <div className='slider-group'>
                <div className='title-large dark-blue-font'>Savings assumption</div>
                <div className='text-medium'>Energy savings are based on the NEED database savings. Either the Median or the Upper Quartile savings can be selected. Median represents current installation practice whilst upper quartile illustrates what may be possible with enhanced installation practice combined with additional quality assurance such as performance measurement.</div>
                <RadioGroupInput
                    value={data.savingsAssumption} options={structure.savingsOptions} handleChange={savingsUpdate} key='savings'
                />
            </div>
        </div>
    )
}

export default Measures;