import React, { useContext, useState } from 'react';
import CheckGroupInput from './Common/Inputs/checkGroup.component';
import { DataContext } from '../context/DataContext';
import { StructureContext } from '../context/StructureContext';
import { OutputContext } from '../context/OutputContext';
import { dataUpdates } from '../utils/processData';
import NumberInput from './Common/Inputs/numeric.component';

const Geography = () => {
    const [data, setData] = useContext(DataContext);
    const [structure] = useContext(StructureContext);
    const [outputs, setOutputs] = useContext(OutputContext);
    const [selectAll, setSelectAll] = useState(true);
    const [countries, setCountries] = useState({
        uk: true,
        england: true,
        wales: true,
        scotland: true,
    });

    const checkHandler = event => {
        let update = data;
        if (event.target.name === 'uk') {
            setCountries({
                uk: true,
                england: true,
                wales: true,
                scotland: true,
            });
            update.countriesValues = {
                england: true,
                wales: true,
                scotland: true,
                northern_ireland: true,
            };
        } else {
            setCountries(prev => {
                prev[event.target.name] = event.target.checked;
                prev.uk = false;
                return({ ...prev });
            });
            update.countriesValues[event.target.name] = event.target.checked;
            update.countriesValues.northern_ireland = false;
        };

        setData(prev => {
            prev = update;
            return ({ ...prev });
        })
        dataUpdates.processData(update, outputs, setOutputs);
    };

    const numericHandler = (event) => {
        let update = data;
        update.assumptions[event.target.id] = event.target.value;
        setData(prev => {
            prev.assumptions[event.target.id] = event.target.value;
            return ({ ...prev });
        });
    };

    const onBlur = () => {
        dataUpdates.processData(data, outputs, setOutputs);
    };

    return (
        <div className='container-content flex-card gap-2'>
            <div className="flex-card">
                <div>
                    <div className='title-medium dark-blue-font'>Geography</div>
                    <div>
                        Select the specific nations or select all for the UK. Note, in this version, Northern Ireland is not able to be selected on its own as the data used is not sufficiently representative.
                    </div>
                </div>

                <CheckGroupInput values={countries} labels={structure.countries} handler={checkHandler} />
            </div>
            <div className="flex-card">
                <div>
                    <div className='title-medium dark-blue-font'>Percentage cost reduction profile</div>
                    <div>
                        Set a cost reduction profile to be applied to measure costs. This allows you to anticipate a reduction in the cost of installing measures due to the scale of activity and increases in manufacturing efficiency. The final value at year 5 is assumed going forwards. Set a negative % for a cost increase.
                    </div>
                </div>
                <div className='grid'>
                    {
                        (structure.assumptions && data.assumptions) && structure.assumptions.map(ass => {
                            return (
                                <NumberInput
                                    key={ass.name}
                                    name={ass.name}
                                    label={ass.label}
                                    value={data.assumptions[ass.name]}
                                    handler={numericHandler}
                                    onBlur={onBlur}
                                    symbol={'%'}
                                    symbolPosition='end'
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="flex-card">
                <div>
                    <div className='title-medium dark-blue-font'>Energy cost assumptions</div>
                    <div>
                        Set cost assumptions applied within the model. The default energy costs are the January 2024 Energy Price Cap with an allowance of £0.044 deducted from the electricity tariff to represent removal of the social levys.  These prices have been maintained throughout the period.  Overtype to change these energy price assumptions.
                    </div>
                </div>
                <div className='grid'>
                    <NumberInput
                        key={'elec_cost'}
                        name={'elec_cost'}
                        label={'Elec £/kWh'}
                        value={data.electricityPrice}
                        handler={(e) => setData(prev => {
                            prev.electricityPrice = e.value;
                            return ({ ...prev });
                        })}
                        onBlur={onBlur}
                        symbol={'£'}
                        symbolPosition='start'
                    />
                    <NumberInput
                        key={'elec_sc'}
                        name={'elec_sc'}
                        label={'Elec standing charge'}
                        value={data.electricityStandingCharge}
                        handler={(e) => setData(prev => {
                            prev.electricityStandingCharge = e.value;
                            return ({ ...prev });
                        })}
                        onBlur={onBlur}
                        symbol={'£'}
                        symbolPosition='start'
                    />
                    <NumberInput
                        key={'gas_cost'}
                        name={'gas_cost'}
                        label={'Gas £/kWh'}
                        value={data.gasPrice}
                        handler={(e) => setData(prev => {
                            prev.gasPrice = e.value;
                            return ({ ...prev });
                        })}
                        onBlur={onBlur}
                        symbol={'£'}
                        symbolPosition='start'
                    />
                    <NumberInput
                        key={'gas_sc'}
                        name={'gas_sc'}
                        label={'Gas standing charge'}
                        value={data.gasStandingCharge}
                        handler={(e) => setData(prev => {
                            prev.gasStandingCharge = e.value;
                            return ({ ...prev });
                        })}
                        onBlur={onBlur}
                        symbol={'£'}
                        symbolPosition='start'
                    />
                </div>
            </div>
        </div>
    )
}

export default Geography