import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomLineChart = (props) => {
    let data = props.outputs;
    let type = props.type;
    let yearly = props.profile;
    let colours = ['#e6194b', '#3cb44b', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']

    if(type === 'yearly_installs') {
        yearly = yearly.map((year, i) => {
            let x = year
            Object.keys(data).forEach(measure => {
                return x[measure] = data[measure][i].installs;
            })
            return x
        })
    };

    let chartData = type === 'yearly_costs' ? data : yearly;
    let width = type === 'yearly_costs' ? 100 : 130;
    let label = type==='yearly_costs' ? 'Investment (£ billions)' : 'Installs';

    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" />
                <YAxis width={width} label={{ value: label, angle: -90 }} />
                <Tooltip />
                <Legend />
                {type === 'yearly_costs' ?
                    <>
                        <Line type="monotone" name="Government spend" dataKey="gov_spend" stroke="#00733F" activeDot={{ r: 8 }} />
                        <Line type="monotone" name="Total spend" dataKey="total_spend" stroke="#3BC1CA" />
                        <Line type="monotone" name="Private spend" dataKey="private_spend" stroke="#F04E66" />
                    </>
                    : <>
                        {
                            Object.keys(data).map((measure, i) => {
                                return <Line type="monotone" name={measure} dataKey={measure} stroke={colours[i]} activeDot={{ r: 8 }} />
                            })
                        }
                    </>
                }
            </LineChart>
        </ResponsiveContainer>
    )
};

export default CustomLineChart;