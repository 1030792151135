import React from 'react';
import './kpi.style.css';
import { Tooltip } from 'react-tooltip';

const KPI = ({ label, value, metric, tooltipId, tooltip }) => {
    return (
        <div className='kpi flex-card text-center'
            data-tooltip-id={tooltipId}
            data-tooltip-content={tooltip}
            data-tooltip-place="bottom"
        >
            <div className='kpi-value heading-large dark-blue-font'>{value}<span className='kpi-metric text-small' dangerouslySetInnerHTML={{ __html: metric }} /></div>
            <div className='kpi-label'>{label}</div>
            <Tooltip id={tooltipId} style={{ maxWidth: '350px', zIndex: '9999' }} />
        </div>
    )
};

export default KPI;