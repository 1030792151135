const scenarioList = [
    { value: 'scenario_1', label: 'Energy Efficiency (only)', },
    { value: 'scenario_2', label: 'Major heat pumps (only)', },
    { value: 'scenario_3', label: 'HP Energy Efficiency - 25 year delivery', },
    { value: 'scenario_4', label: 'Reset all', },
    { value: 'custom', label: 'Uploaded scenario', },
];

const scenario_1 = {
    metaData: {
        name: 'scenario_1',
        label: 'Energy Efficiency (only)',
        description: 'Energy efficiency measures applied - no heating.'
    },
    measuresValues: {
        uptake_cwi: 100,
        uptake_ewi: 50,
        uptake_pwi: 50,
        uptake_lif: 100,
        uptake_litu: 100,
        uptake_fri: 100,
        uptake_hp: 0,
        uptake_olch: 0,
        uptake_pv: 20,
        uptake_sc: 100,
        uptake_gl: 80,
        uptake_st: 0,
        uptake_bat: 0,
        uptake_light: 100,
        uptake_floor: 60,
        uptake_rmi: 70,
        uptake_vent: 30,
        uptake_overheat: 0,
        uptake_water: 50,
    },
    otherCostsValues: {
        other_costs_training: 0.5,
        other_costs_capacity_expansion: 5,
        other_costs_capacity_development: 0.5,
        other_costs_r_and_d: 0,
        other_costs_comms: 1.25,
    },
    countriesValues: {
        england: true,
        wales: true,
        scotland: true,
        northern_ireland: true,
    },
    deliveryYearsValues: {
        owner_occupied_fuel_poor_asset_poor_delivery_years: 10,
        owner_occupied_fuel_poor_asset_rich_delivery_years: 10,
        owner_occupied_near_fuel_poor_asset_poor_delivery_years: 10,
        owner_occupied_near_fuel_poor_asset_rich_delivery_years: 10,
        owner_occupied_non_fuel_poor_asset_poor_delivery_years: 25,
        owner_occupied_non_fuel_poor_asset_rich_delivery_years: 25,
        rental_private_fuel_poor_delivery_years: 10,
        rental_private_near_fuel_poor_delivery_years: 10,
        rental_private_non_fuel_poor_delivery_years: 15,
        rental_social_fuel_poor_delivery_years: 10,
        rental_social_near_fuel_poor_delivery_years: 10,
        rental_social_non_fuel_poor_delivery_years: 10,
    },
    fundingYearsValues: {
        owner_occupied_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_non_fuel_poor_asset_poor_funding_years: 20,
        owner_occupied_non_fuel_poor_asset_rich_funding_years: 20,
        rental_private_fuel_poor_funding_years: 10,
        rental_private_near_fuel_poor_funding_years: 10,
        rental_private_non_fuel_poor_funding_years: 10,
        rental_social_fuel_poor_funding_years: 10,
        rental_social_near_fuel_poor_funding_years: 10,
        rental_social_non_fuel_poor_funding_years: 10,
    },
    interventionsInputs: {
        rental_social_fuel_poor: 80,
        rental_social_near_fuel_poor: 80,
        rental_social_non_fuel_poor: 80,
        rental_private_fuel_poor: 50,
        rental_private_near_fuel_poor: 50,
        rental_private_non_fuel_poor: 25,
        owner_occupied_fuel_poor_asset_poor: 80,
        owner_occupied_fuel_poor_asset_rich: 25,
        owner_occupied_near_fuel_poor_asset_poor: 80,
        owner_occupied_near_fuel_poor_asset_rich: 25,
        owner_occupied_non_fuel_poor_asset_poor: 50,
        owner_occupied_non_fuel_poor_asset_rich: 10,
    },
    interventionsValues: {
        rental_social_fuel_poor_d: 80,
        rental_social_fuel_poor_e: 80,
        rental_social_fuel_poor_f: 80,
        rental_social_fuel_poor_g: 80,
        rental_social_near_fuel_poor_d: 80,
        rental_social_near_fuel_poor_e: 80,
        rental_social_near_fuel_poor_f: 80,
        rental_social_near_fuel_poor_g: 80,
        rental_social_non_fuel_poor_a: 80,
        rental_social_non_fuel_poor_b: 80,
        rental_social_non_fuel_poor_c: 80,
        rental_social_non_fuel_poor_d: 80,
        rental_social_non_fuel_poor_e: 80,
        rental_social_non_fuel_poor_f: 80,
        rental_social_non_fuel_poor_g: 80,
        rental_private_fuel_poor_d: 50,
        rental_private_fuel_poor_e: 50,
        rental_private_fuel_poor_f: 50,
        rental_private_fuel_poor_g: 50,
        rental_private_near_fuel_poor_d: 50,
        rental_private_near_fuel_poor_e: 50,
        rental_private_near_fuel_poor_f: 50,
        rental_private_near_fuel_poor_g: 50,
        rental_private_non_fuel_poor_a: 25,
        rental_private_non_fuel_poor_b: 25,
        rental_private_non_fuel_poor_c: 25,
        rental_private_non_fuel_poor_d: 25,
        rental_private_non_fuel_poor_e: 25,
        rental_private_non_fuel_poor_f: 25,
        rental_private_non_fuel_poor_g: 25,
        owner_occupied_fuel_poor_asset_poor_d: 80,
        owner_occupied_fuel_poor_asset_poor_e: 80,
        owner_occupied_fuel_poor_asset_poor_f: 80,
        owner_occupied_fuel_poor_asset_poor_g: 80,
        owner_occupied_fuel_poor_asset_rich_d: 25,
        owner_occupied_fuel_poor_asset_rich_e: 25,
        owner_occupied_fuel_poor_asset_rich_f: 25,
        owner_occupied_fuel_poor_asset_rich_g: 25,
        owner_occupied_near_fuel_poor_asset_poor_d: 80,
        owner_occupied_near_fuel_poor_asset_poor_e: 80,
        owner_occupied_near_fuel_poor_asset_poor_f: 80,
        owner_occupied_near_fuel_poor_asset_poor_g: 80,
        owner_occupied_near_fuel_poor_asset_rich_d: 25,
        owner_occupied_near_fuel_poor_asset_rich_e: 25,
        owner_occupied_near_fuel_poor_asset_rich_f: 25,
        owner_occupied_near_fuel_poor_asset_rich_g: 25,
        owner_occupied_non_fuel_poor_asset_poor_a: 50,
        owner_occupied_non_fuel_poor_asset_poor_b: 50,
        owner_occupied_non_fuel_poor_asset_poor_c: 50,
        owner_occupied_non_fuel_poor_asset_poor_d: 50,
        owner_occupied_non_fuel_poor_asset_poor_e: 50,
        owner_occupied_non_fuel_poor_asset_poor_f: 50,
        owner_occupied_non_fuel_poor_asset_poor_g: 50,
        owner_occupied_non_fuel_poor_asset_rich_a: 10,
        owner_occupied_non_fuel_poor_asset_rich_b: 10,
        owner_occupied_non_fuel_poor_asset_rich_c: 10,
        owner_occupied_non_fuel_poor_asset_rich_d: 10,
        owner_occupied_non_fuel_poor_asset_rich_e: 10,
        owner_occupied_non_fuel_poor_asset_rich_f: 10,
        owner_occupied_non_fuel_poor_asset_rich_g: 10,
    },
    hpRatio: 'hp_0_olch_0',
    comments: {
        rental_social_fuel_poor: '',
        rental_social_near_fuel_poor: '',
        rental_social_non_fuel_poor: '',
        rental_private_fuel_poor: '',
        rental_private_near_fuel_poor: '',
        rental_private_non_fuel_poor: '',
        owner_occupied_fuel_poor_asset_poor: '',
        owner_occupied_fuel_poor_asset_rich: '',
        owner_occupied_near_fuel_poor_asset_poor: '',
        owner_occupied_near_fuel_poor_asset_rich: '',
        owner_occupied_non_fuel_poor_asset_poor: '',
        owner_occupied_non_fuel_poor_asset_rich: ''
    },
    assumptions: {
        cost_reduction_1: -10,
        cost_reduction_2: 0,
        cost_reduction_3: 0,
        cost_reduction_4: 0,
        cost_reduction_5: 0,
    },
    electricityPrice: 0.231,
    electricityStandingCharge: 0.53,
    gasPrice: 0.07,
    gasStandingCharge: 0.30,
    savingsAssumption: 'upper',
    scopAssumption: 'high',
};

// Tested- correct
const scenario_2 = {
    metaData: {
        name: 'scenario_2',
        label: 'Major heat pumps only',
        description: 'Test scenario developed when creating the Excel model.'
    },
    measuresValues: {
        uptake_cwi: 0,
        uptake_ewi: 0,
        uptake_pwi: 0,
        uptake_lif: 0,
        uptake_litu: 0,
        uptake_fri: 0,
        uptake_hp: 90,
        uptake_olch: 10,
        uptake_pv: 0,
        uptake_sc: 0,
        uptake_gl: 0,
        uptake_st: 0,
        uptake_bat: 0,
        uptake_light: 0,
        uptake_floor: 0,
        uptake_rmi: 0,
        uptake_vent: 0,
        uptake_overheat: 0,
        uptake_water: 0,
    },
    otherCostsValues: {
        other_costs_training: 0,
        other_costs_capacity_expansion: 0,
        other_costs_capacity_development: 0,
        other_costs_r_and_d: 0,
        other_costs_comms: 0,
    },
    countriesValues: {
        england: true,
        wales: true,
        scotland: true,
        northern_ireland: true,
    },
    deliveryYearsValues: {
        owner_occupied_fuel_poor_asset_poor_delivery_years: 25,
        owner_occupied_fuel_poor_asset_rich_delivery_years: 25,
        owner_occupied_near_fuel_poor_asset_poor_delivery_years: 25,
        owner_occupied_near_fuel_poor_asset_rich_delivery_years: 25,
        owner_occupied_non_fuel_poor_asset_poor_delivery_years: 25,
        owner_occupied_non_fuel_poor_asset_rich_delivery_years: 25,
        rental_private_fuel_poor_delivery_years: 25,
        rental_private_near_fuel_poor_delivery_years: 25,
        rental_private_non_fuel_poor_delivery_years: 25,
        rental_social_fuel_poor_delivery_years: 25,
        rental_social_near_fuel_poor_delivery_years: 25,
        rental_social_non_fuel_poor_delivery_years: 25,
    },
    fundingYearsValues: {
        owner_occupied_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_non_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_non_fuel_poor_asset_rich_funding_years: 10,
        rental_private_fuel_poor_funding_years: 10,
        rental_private_near_fuel_poor_funding_years: 10,
        rental_private_non_fuel_poor_funding_years: 10,
        rental_social_fuel_poor_funding_years: 12,
        rental_social_near_fuel_poor_funding_years: 12,
        rental_social_non_fuel_poor_funding_years: 15,
    },
    interventionsInputs: {
        rental_social_fuel_poor: 80,
        rental_social_near_fuel_poor: 80,
        rental_social_non_fuel_poor: 80,
        rental_private_fuel_poor: 25,
        rental_private_near_fuel_poor: 25,
        rental_private_non_fuel_poor: 25,
        owner_occupied_fuel_poor_asset_poor: 40,
        owner_occupied_fuel_poor_asset_rich: 40,
        owner_occupied_near_fuel_poor_asset_poor: 40,
        owner_occupied_near_fuel_poor_asset_rich: 40,
        owner_occupied_non_fuel_poor_asset_poor: 25,
        owner_occupied_non_fuel_poor_asset_rich: 25,
    },
    interventionsValues: {
        rental_social_fuel_poor_d: 80,
        rental_social_fuel_poor_e: 80,
        rental_social_fuel_poor_f: 80,
        rental_social_fuel_poor_g: 80,
        rental_social_near_fuel_poor_d: 80,
        rental_social_near_fuel_poor_e: 80,
        rental_social_near_fuel_poor_f: 80,
        rental_social_near_fuel_poor_g: 80,
        rental_social_non_fuel_poor_a: 80,
        rental_social_non_fuel_poor_b: 80,
        rental_social_non_fuel_poor_c: 80,
        rental_social_non_fuel_poor_d: 80,
        rental_social_non_fuel_poor_e: 80,
        rental_social_non_fuel_poor_f: 80,
        rental_social_non_fuel_poor_g: 80,
        rental_private_fuel_poor_d: 25,
        rental_private_fuel_poor_e: 25,
        rental_private_fuel_poor_f: 25,
        rental_private_fuel_poor_g: 25,
        rental_private_near_fuel_poor_d: 25,
        rental_private_near_fuel_poor_e: 25,
        rental_private_near_fuel_poor_f: 25,
        rental_private_near_fuel_poor_g: 25,
        rental_private_non_fuel_poor_a: 25,
        rental_private_non_fuel_poor_b: 25,
        rental_private_non_fuel_poor_c: 25,
        rental_private_non_fuel_poor_d: 25,
        rental_private_non_fuel_poor_e: 25,
        rental_private_non_fuel_poor_f: 25,
        rental_private_non_fuel_poor_g: 25,
        owner_occupied_fuel_poor_asset_poor_d: 40,
        owner_occupied_fuel_poor_asset_poor_e: 40,
        owner_occupied_fuel_poor_asset_poor_f: 40,
        owner_occupied_fuel_poor_asset_poor_g: 40,
        owner_occupied_fuel_poor_asset_rich_d: 40,
        owner_occupied_fuel_poor_asset_rich_e: 40,
        owner_occupied_fuel_poor_asset_rich_f: 40,
        owner_occupied_fuel_poor_asset_rich_g: 40,
        owner_occupied_near_fuel_poor_asset_poor_d: 40,
        owner_occupied_near_fuel_poor_asset_poor_e: 40,
        owner_occupied_near_fuel_poor_asset_poor_f: 40,
        owner_occupied_near_fuel_poor_asset_poor_g: 40,
        owner_occupied_near_fuel_poor_asset_rich_d: 40,
        owner_occupied_near_fuel_poor_asset_rich_e: 40,
        owner_occupied_near_fuel_poor_asset_rich_f: 40,
        owner_occupied_near_fuel_poor_asset_rich_g: 40,
        owner_occupied_non_fuel_poor_asset_poor_a: 25,
        owner_occupied_non_fuel_poor_asset_poor_b: 25,
        owner_occupied_non_fuel_poor_asset_poor_c: 25,
        owner_occupied_non_fuel_poor_asset_poor_d: 25,
        owner_occupied_non_fuel_poor_asset_poor_e: 25,
        owner_occupied_non_fuel_poor_asset_poor_f: 25,
        owner_occupied_non_fuel_poor_asset_poor_g: 25,
        owner_occupied_non_fuel_poor_asset_rich_a: 25,
        owner_occupied_non_fuel_poor_asset_rich_b: 25,
        owner_occupied_non_fuel_poor_asset_rich_c: 25,
        owner_occupied_non_fuel_poor_asset_rich_d: 25,
        owner_occupied_non_fuel_poor_asset_rich_e: 25,
        owner_occupied_non_fuel_poor_asset_rich_f: 25,
        owner_occupied_non_fuel_poor_asset_rich_g: 25,
    },
    hpRatio: 'hp_90_olch_10',
    comments: {
        rental_social_fuel_poor: '',
        rental_social_near_fuel_poor: '',
        rental_social_non_fuel_poor: '',
        rental_private_fuel_poor: '',
        rental_private_near_fuel_poor: '',
        rental_private_non_fuel_poor: '',
        owner_occupied_fuel_poor_asset_poor: '',
        owner_occupied_fuel_poor_asset_rich: '',
        owner_occupied_near_fuel_poor_asset_poor: '',
        owner_occupied_near_fuel_poor_asset_rich: '',
        owner_occupied_non_fuel_poor_asset_poor: '',
        owner_occupied_non_fuel_poor_asset_rich: ''
    },
    assumptions: {
        cost_reduction_1: -10,
        cost_reduction_2: 0,
        cost_reduction_3: 0,
        cost_reduction_4: 0,
        cost_reduction_5: 0,
    },
    electricityPrice: 0.231,
    electricityStandingCharge: 0.53,
    gasPrice: 0.07,
    gasStandingCharge: 0.30,
    savingsAssumption: 'median',
    scopAssumption: 'high',
}

//
const scenario_3 = {
    metaData: {
        name: 'scenario_3',
        label: 'C-Path 50% HP - 23',
        description: 'Test scenario developed when creating the Excel model.'
    },
    measuresValues: {
        uptake_cwi: 100,
        uptake_ewi: 50,
        uptake_pwi: 50,
        uptake_lif: 100,
        uptake_litu: 100,
        uptake_fri: 100,
        uptake_hp: 90,
        uptake_olch: 10,
        uptake_pv: 20,
        uptake_sc: 100,
        uptake_gl: 80,
        uptake_st: 0,
        uptake_bat: 0,
        uptake_light: 100,
        uptake_floor: 60,
        uptake_rmi: 70,
        uptake_vent: 30,
        uptake_overheat: 0,
        uptake_water: 50,
    },
    otherCostsValues: {
        other_costs_training: 0.5,
        other_costs_capacity_expansion: 5,
        other_costs_capacity_development: 0.5,
        other_costs_r_and_d: 0,
        other_costs_comms: 1.25,
    },
    countriesValues: {
        england: true,
        wales: true,
        scotland: true,
        northern_ireland: true,
    },
    deliveryYearsValues: {
        owner_occupied_fuel_poor_asset_poor_delivery_years: 12,
        owner_occupied_fuel_poor_asset_rich_delivery_years: 12,
        owner_occupied_near_fuel_poor_asset_poor_delivery_years: 12,
        owner_occupied_near_fuel_poor_asset_rich_delivery_years: 12,
        owner_occupied_non_fuel_poor_asset_poor_delivery_years: 20,
        owner_occupied_non_fuel_poor_asset_rich_delivery_years: 25,
        rental_private_fuel_poor_delivery_years: 15,
        rental_private_near_fuel_poor_delivery_years: 15,
        rental_private_non_fuel_poor_delivery_years: 20,
        rental_social_fuel_poor_delivery_years: 15,
        rental_social_near_fuel_poor_delivery_years: 15,
        rental_social_non_fuel_poor_delivery_years: 20,
    },
    fundingYearsValues: {
        owner_occupied_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_poor_funding_years: 10,
        owner_occupied_near_fuel_poor_asset_rich_funding_years: 10,
        owner_occupied_non_fuel_poor_asset_poor_funding_years: 15,
        owner_occupied_non_fuel_poor_asset_rich_funding_years: 15,
        rental_private_fuel_poor_funding_years: 10,
        rental_private_near_fuel_poor_funding_years: 10,
        rental_private_non_fuel_poor_funding_years: 10,
        rental_social_fuel_poor_funding_years: 12,
        rental_social_near_fuel_poor_funding_years: 12,
        rental_social_non_fuel_poor_funding_years: 15,
    },
    interventionsInputs: {
        rental_social_fuel_poor: 80,
        rental_social_near_fuel_poor: 80,
        rental_social_non_fuel_poor: 80,
        rental_private_fuel_poor: 40,
        rental_private_near_fuel_poor: 40,
        rental_private_non_fuel_poor: 25,
        owner_occupied_fuel_poor_asset_poor: 60,
        owner_occupied_fuel_poor_asset_rich: 30,
        owner_occupied_near_fuel_poor_asset_poor: 60,
        owner_occupied_near_fuel_poor_asset_rich: 30,
        owner_occupied_non_fuel_poor_asset_poor: 40,
        owner_occupied_non_fuel_poor_asset_rich: 20,
    },
    interventionsValues: {
        rental_social_fuel_poor_d: 80,
        rental_social_fuel_poor_e: 80,
        rental_social_fuel_poor_f: 80,
        rental_social_fuel_poor_g: 80,
        rental_social_near_fuel_poor_d: 80,
        rental_social_near_fuel_poor_e: 80,
        rental_social_near_fuel_poor_f: 80,
        rental_social_near_fuel_poor_g: 80,
        rental_social_non_fuel_poor_a: 80,
        rental_social_non_fuel_poor_b: 80,
        rental_social_non_fuel_poor_c: 80,
        rental_social_non_fuel_poor_d: 80,
        rental_social_non_fuel_poor_e: 80,
        rental_social_non_fuel_poor_f: 80,
        rental_social_non_fuel_poor_g: 80,
        rental_private_fuel_poor_d: 40,
        rental_private_fuel_poor_e: 40,
        rental_private_fuel_poor_f: 40,
        rental_private_fuel_poor_g: 40,
        rental_private_near_fuel_poor_d: 40,
        rental_private_near_fuel_poor_e: 40,
        rental_private_near_fuel_poor_f: 40,
        rental_private_near_fuel_poor_g: 40,
        rental_private_non_fuel_poor_a: 25,
        rental_private_non_fuel_poor_b: 25,
        rental_private_non_fuel_poor_c: 25,
        rental_private_non_fuel_poor_d: 25,
        rental_private_non_fuel_poor_e: 25,
        rental_private_non_fuel_poor_f: 25,
        rental_private_non_fuel_poor_g: 25,
        owner_occupied_fuel_poor_asset_poor_d: 60,
        owner_occupied_fuel_poor_asset_poor_e: 60,
        owner_occupied_fuel_poor_asset_poor_f: 60,
        owner_occupied_fuel_poor_asset_poor_g: 60,
        owner_occupied_fuel_poor_asset_rich_d: 30,
        owner_occupied_fuel_poor_asset_rich_e: 30,
        owner_occupied_fuel_poor_asset_rich_f: 30,
        owner_occupied_fuel_poor_asset_rich_g: 30,
        owner_occupied_near_fuel_poor_asset_poor_d: 60,
        owner_occupied_near_fuel_poor_asset_poor_e: 60,
        owner_occupied_near_fuel_poor_asset_poor_f: 60,
        owner_occupied_near_fuel_poor_asset_poor_g: 60,
        owner_occupied_near_fuel_poor_asset_rich_d: 30,
        owner_occupied_near_fuel_poor_asset_rich_e: 30,
        owner_occupied_near_fuel_poor_asset_rich_f: 30,
        owner_occupied_near_fuel_poor_asset_rich_g: 30,
        owner_occupied_non_fuel_poor_asset_poor_a: 40,
        owner_occupied_non_fuel_poor_asset_poor_b: 40,
        owner_occupied_non_fuel_poor_asset_poor_c: 40,
        owner_occupied_non_fuel_poor_asset_poor_d: 40,
        owner_occupied_non_fuel_poor_asset_poor_e: 40,
        owner_occupied_non_fuel_poor_asset_poor_f: 40,
        owner_occupied_non_fuel_poor_asset_poor_g: 40,
        owner_occupied_non_fuel_poor_asset_rich_a: 20,
        owner_occupied_non_fuel_poor_asset_rich_b: 20,
        owner_occupied_non_fuel_poor_asset_rich_c: 20,
        owner_occupied_non_fuel_poor_asset_rich_d: 20,
        owner_occupied_non_fuel_poor_asset_rich_e: 20,
        owner_occupied_non_fuel_poor_asset_rich_f: 20,
        owner_occupied_non_fuel_poor_asset_rich_g: 20,
    },
    hpRatio: 'hp_90_olch_10',
    comments: {
        rental_social_fuel_poor: '',
        rental_social_near_fuel_poor: '',
        rental_social_non_fuel_poor: '',
        rental_private_fuel_poor: '',
        rental_private_near_fuel_poor: '',
        rental_private_non_fuel_poor: '',
        owner_occupied_fuel_poor_asset_poor: '',
        owner_occupied_fuel_poor_asset_rich: '',
        owner_occupied_near_fuel_poor_asset_poor: '',
        owner_occupied_near_fuel_poor_asset_rich: '',
        owner_occupied_non_fuel_poor_asset_poor: '',
        owner_occupied_non_fuel_poor_asset_rich: ''
    },
    assumptions: {
        cost_reduction_1: -10,
        cost_reduction_2: 0,
        cost_reduction_3: 0,
        cost_reduction_4: 0,
        cost_reduction_5: 0,
    },
    electricityPrice: 0.231,
    electricityStandingCharge: 0.53,
    gasPrice: 0.07,
    gasStandingCharge: 0.30,
    savingsAssumption: 'upper',
    scopAssumption: 'high',
}

const scenario_4 = {
    metaData: {
        name: 'scenario_4',
        label: 'Reset all',
        description: 'Test scenario developed when creating the Excel model.'
    },
    measuresValues: {
        uptake_cwi: 0,
        uptake_ewi: 0,
        uptake_pwi: 0,
        uptake_lif: 0,
        uptake_litu: 0,
        uptake_fri: 0,
        uptake_hp: 0,
        uptake_olch: 0,
        uptake_pv: 0,
        uptake_sc: 0,
        uptake_gl: 0,
        uptake_st: 0,
        uptake_bat: 0,
        uptake_light: 0,
        uptake_floor: 0,
        uptake_rmi: 0,
        uptake_vent: 0,
        uptake_overheat: 0,
        uptake_water: 0,
    },
    otherCostsValues: {
        other_costs_training: 0,
        other_costs_capacity_expansion: 0,
        other_costs_capacity_development: 0,
        other_costs_r_and_d: 0,
        other_costs_comms: 0,
    },
    countriesValues: {
        england: true,
        wales: true,
        scotland: true,
        northern_ireland: true,
    },
    deliveryYearsValues: {
        owner_occupied_fuel_poor_asset_poor_delivery_years: 20,
        owner_occupied_fuel_poor_asset_rich_delivery_years: 20,
        owner_occupied_near_fuel_poor_asset_poor_delivery_years: 20,
        owner_occupied_near_fuel_poor_asset_rich_delivery_years: 20,
        owner_occupied_non_fuel_poor_asset_poor_delivery_years: 20,
        owner_occupied_non_fuel_poor_asset_rich_delivery_years: 20,
        rental_private_fuel_poor_delivery_years: 20,
        rental_private_near_fuel_poor_delivery_years: 20,
        rental_private_non_fuel_poor_delivery_years: 20,
        rental_social_fuel_poor_delivery_years: 20,
        rental_social_near_fuel_poor_delivery_years: 20,
        rental_social_non_fuel_poor_delivery_years: 20,
    },
    fundingYearsValues: {
        owner_occupied_fuel_poor_asset_poor_funding_years: 20,
        owner_occupied_fuel_poor_asset_rich_funding_years: 20,
        owner_occupied_near_fuel_poor_asset_poor_funding_years: 20,
        owner_occupied_near_fuel_poor_asset_rich_funding_years: 20,
        owner_occupied_non_fuel_poor_asset_poor_funding_years: 20,
        owner_occupied_non_fuel_poor_asset_rich_funding_years: 20,
        rental_private_fuel_poor_funding_years: 20,
        rental_private_near_fuel_poor_funding_years: 20,
        rental_private_non_fuel_poor_funding_years: 20,
        rental_social_fuel_poor_funding_years: 20,
        rental_social_near_fuel_poor_funding_years: 20,
        rental_social_non_fuel_poor_funding_years: 20,
    },
    interventionsInputs: {
        rental_social_fuel_poor: 0,
        rental_social_near_fuel_poor: 0,
        rental_social_non_fuel_poor: 0,
        rental_private_fuel_poor: 0,
        rental_private_near_fuel_poor: 0,
        rental_private_non_fuel_poor: 0,
        owner_occupied_fuel_poor_asset_poor: 0,
        owner_occupied_fuel_poor_asset_rich: 0,
        owner_occupied_near_fuel_poor_asset_poor: 0,
        owner_occupied_near_fuel_poor_asset_rich: 0,
        owner_occupied_non_fuel_poor_asset_poor: 0,
        owner_occupied_non_fuel_poor_asset_rich: 0,
    },
    interventionsValues: {
        rental_social_fuel_poor_d: 0,
        rental_social_fuel_poor_e: 0,
        rental_social_fuel_poor_f: 0,
        rental_social_fuel_poor_g: 0,
        rental_social_near_fuel_poor_d: 0,
        rental_social_near_fuel_poor_e: 0,
        rental_social_near_fuel_poor_f: 0,
        rental_social_near_fuel_poor_g: 0,
        rental_social_non_fuel_poor_a: 0,
        rental_social_non_fuel_poor_b: 0,
        rental_social_non_fuel_poor_c: 0,
        rental_social_non_fuel_poor_d: 0,
        rental_social_non_fuel_poor_e: 0,
        rental_social_non_fuel_poor_f: 0,
        rental_social_non_fuel_poor_g: 0,
        rental_private_fuel_poor_d: 0,
        rental_private_fuel_poor_e: 0,
        rental_private_fuel_poor_f: 0,
        rental_private_fuel_poor_g: 0,
        rental_private_near_fuel_poor_d: 0,
        rental_private_near_fuel_poor_e: 0,
        rental_private_near_fuel_poor_f: 0,
        rental_private_near_fuel_poor_g: 0,
        rental_private_non_fuel_poor_a: 0,
        rental_private_non_fuel_poor_b: 0,
        rental_private_non_fuel_poor_c: 0,
        rental_private_non_fuel_poor_d: 0,
        rental_private_non_fuel_poor_e: 0,
        rental_private_non_fuel_poor_f: 0,
        rental_private_non_fuel_poor_g: 0,
        owner_occupied_fuel_poor_asset_poor_d: 0,
        owner_occupied_fuel_poor_asset_poor_e: 0,
        owner_occupied_fuel_poor_asset_poor_f: 0,
        owner_occupied_fuel_poor_asset_poor_g: 0,
        owner_occupied_fuel_poor_asset_rich_d: 0,
        owner_occupied_fuel_poor_asset_rich_e: 0,
        owner_occupied_fuel_poor_asset_rich_f: 0,
        owner_occupied_fuel_poor_asset_rich_g: 0,
        owner_occupied_near_fuel_poor_asset_poor_d: 0,
        owner_occupied_near_fuel_poor_asset_poor_e: 0,
        owner_occupied_near_fuel_poor_asset_poor_f: 0,
        owner_occupied_near_fuel_poor_asset_poor_g: 0,
        owner_occupied_near_fuel_poor_asset_rich_d: 0,
        owner_occupied_near_fuel_poor_asset_rich_e: 0,
        owner_occupied_near_fuel_poor_asset_rich_f: 0,
        owner_occupied_near_fuel_poor_asset_rich_g: 0,
        owner_occupied_non_fuel_poor_asset_poor_a: 0,
        owner_occupied_non_fuel_poor_asset_poor_b: 0,
        owner_occupied_non_fuel_poor_asset_poor_c: 0,
        owner_occupied_non_fuel_poor_asset_poor_d: 0,
        owner_occupied_non_fuel_poor_asset_poor_e: 0,
        owner_occupied_non_fuel_poor_asset_poor_f: 0,
        owner_occupied_non_fuel_poor_asset_poor_g: 0,
        owner_occupied_non_fuel_poor_asset_rich_a: 0,
        owner_occupied_non_fuel_poor_asset_rich_b: 0,
        owner_occupied_non_fuel_poor_asset_rich_c: 0,
        owner_occupied_non_fuel_poor_asset_rich_d: 0,
        owner_occupied_non_fuel_poor_asset_rich_e: 0,
        owner_occupied_non_fuel_poor_asset_rich_f: 0,
        owner_occupied_non_fuel_poor_asset_rich_g: 0,
    },
    hpRatio: 'hp_0_olch_0',
    comments: {
        rental_social_fuel_poor: '',
        rental_social_near_fuel_poor: '',
        rental_social_non_fuel_poor: '',
        rental_private_fuel_poor: '',
        rental_private_near_fuel_poor: '',
        rental_private_non_fuel_poor: '',
        owner_occupied_fuel_poor_asset_poor: '',
        owner_occupied_fuel_poor_asset_rich: '',
        owner_occupied_near_fuel_poor_asset_poor: '',
        owner_occupied_near_fuel_poor_asset_rich: '',
        owner_occupied_non_fuel_poor_asset_poor: '',
        owner_occupied_non_fuel_poor_asset_rich: ''
    },
    assumptions: {
        cost_reduction_1: -10,
        cost_reduction_2: 0,
        cost_reduction_3: 0,
        cost_reduction_4: 0,
        cost_reduction_5: 0,
    },
    electricityPrice: 0.231,
    electricityStandingCharge: 0.53,
    gasPrice: 0.07,
    gasStandingCharge: 0.30,
    savingsAssumption: 'median',
    scopAssumption: 'high',
}

export const scenarios = {
    scenarioList,
    scenario_1,
    scenario_2,
    scenario_3,
    scenario_4
}