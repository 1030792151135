import React from 'react';
import Slider from '@mui/material/Slider';
import './slider.style.css';
import formatNumber from '../../../utils/formatNumber';
import { Tooltip } from 'react-tooltip';

const SliderInput = (props) => {
    let { name, label, disabled, value, quantity, handler, tooltip } = props;

    let valuetext = (value) => {
        return `${value}%`;
    }

    return (
        <div className='slider-container' key={`slider ${name}`}>
            <label htmlFor={name} className='slider-label'
                data-tooltip-id={tooltip ? name : ''}
                data-tooltip-content={tooltip ? tooltip : ''}
                data-tooltip-place="bottom"
            >
                {label}: {formatNumber(Number(quantity))}
                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handler(name)}
                    aria-labelledby="input-slider"
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    disabled={disabled}
                    sx={{
                        color: '#304578',
                    }}
                />
            </label>
            {tooltip && <Tooltip id={name} style={{ maxWidth: '300px', zIndex: '9999' }} />}

        </div>
    )
}

export default SliderInput