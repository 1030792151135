import React, { useContext, useState } from 'react';
import DownloadButton from './Common/Outputs/download.component';
import TextArea from './Common/Inputs/textArea.component';
import { DataContext } from '../context/DataContext';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
    Bam,
    Berkeley,
    Buro,
    Cbre,
    Grovsenor,
    Hoare,
    Isg,
    Jll,
    Lloyds,
    Rockwool,
    Turner,
} from '../images/logosPartners/index';

import {
    Clarion,
    Eon,
    Hta,
    Lendology,
    London,
    Mcs,
    Se2,
} from '../images/logosProject/index';

import {
    Arup,
    Leeds,
    Pwc,
    Thermly,
} from '../images/logosResearch/index';

import {
    Building,
    Ikea,
    Laudes,
} from '../images/logosFunders/index';

const Close = () => {
    const [data, setData] = useContext(DataContext);
    const [settings] = useState({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        centerMode: true,
    });

    const logosPartners = [
        Bam,
        Berkeley,
        Buro,
        Cbre,
        Grovsenor,
        Hoare,
        Isg,
        Jll,
        Lloyds,
        Rockwool,
        Turner,
    ];
    const logosProject = [
        Clarion,
        Eon,
        Hta,
        Lendology,
        London,
        Mcs,
        Se2,
    ];
    const logosResearch = [
        Arup,
        Leeds,
        Pwc,
        Thermly,
    ];
    const logosFunders = [
        Building,
        Ikea,
        Laudes,
    ];

    const commentHandler = (event, name) => {
        let value = event.target.value;
        let update = data;
        update.comments[name] = value;

        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
    };

    return (
        <>
            <div className='flex-card'>
                <div className='title-medium dark-blue-font'>Export scenario</div>
                <div>Add some final comments to contextualise your scenario and then download your parameters. These can be loaded back into the the Calculator in a future session to allow you to pick up where you left off.</div>
                <div className='grid'>
                    <TextArea value={data.comments['final_comments']} label="Final comments" onChange={(e) => { commentHandler(e, 'final_comments'); }} />
                </div>
                <DownloadButton />
            </div>

            <div className='heading-medium dark-blue-font slider-group'>Programme partners</div>
            <div className='flex-card'>
                {
                    logosPartners && <Slider {...settings}>
                        {
                            logosPartners.map((logo, index) => (
                                <div key={index + '_logo'} className="flex-center" style={{ height: "100%" }}>
                                    <img src={logo} alt='logo' className="partner-logos" />
                                </div>
                            ))
                        }
                    </Slider>
                }
            </div>

            <div className='heading-medium dark-blue-font slider-group'>Project partners</div>
            <div className='flex-card'>
                {
                    logosProject && <Slider {...settings}>
                        {
                            logosProject.map((logo, index) => (
                                <div key={index + '_logo'} className="flex-center" style={{ height: "100%" }}>
                                    <img src={logo} alt='logo' className="partner-logos" />
                                </div>
                            ))
                        }
                    </Slider>
                }
            </div>

            <div className='heading-medium dark-blue-font slider-group'>Research partners</div>
            <div className='flex-card'>
                {
                    logosResearch && <Slider {...settings}>
                        {
                            logosResearch.map((logo, index) => (
                                <div key={index + '_logo'} className="flex-center" style={{ height: "100%" }}>
                                    <img src={logo} alt='logo' className="partner-logos" />
                                </div>
                            ))
                        }
                    </Slider>
                }
            </div>

            <div className='heading-medium dark-blue-font slider-group'>Additional funders</div>
            <div className='flex-card'>
                {
                    logosFunders && <Slider {...settings}>
                        {
                            logosFunders.map((logo, index) => (
                                <div key={index + '_logo'} className="flex-center" style={{ height: "100%" }}>
                                    <img src={logo} alt='logo' className="partner-logos" />
                                </div>
                            ))
                        }
                    </Slider>
                }
            </div>
        </>
    )
}

export default Close