import React from 'react';
import { TextField } from '@mui/material';

const TextArea = (props) => {
    const { value, label, onChange } = props;
    return (
        <TextField
            value={value}
            label={label}
            multiline
            rows={2}
            onChange={onChange}
        />
    )
}

export default TextArea;