import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { DataContextProvider } from './context/DataContext';
import { StructureContextProvider } from './context/StructureContext';
import { OutputContextProvider } from './context/OutputContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StructureContextProvider>
    <DataContextProvider>
      <OutputContextProvider>
        <App />
      </OutputContextProvider>
    </DataContextProvider>
  </StructureContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
