import React, { useState, useContext, useEffect } from 'react';
import { OutputContext } from '../context/OutputContext';
import { StructureContext } from '../context/StructureContext';
import KPI from './Common/Outputs/kpi.component';
import CustomLineChart from './Common/Outputs/lineChart.component';
import Separator from './Layout/separator.component';
import { Tooltip } from 'react-tooltip';

const KPIs = () => {
    const [outputs] = useContext(OutputContext);
    const [structure] = useContext(StructureContext);
    const [text, setText] = useState('costs');
    const [selected, setSelected] = useState({
        uptake_cwi: false,
        uptake_ewi: false,
        uptake_pwi: false,
        uptake_lif: false,
        uptake_litu: false,
        uptake_fri: false,
        uptake_pv: false,
        uptake_sc: false,
        uptake_gl: false,
        uptake_light: false,
        uptake_floor: false,
        uptake_rmi: false,
        uptake_vent: false,
        uptake_overheat: false,
        uptake_water: false,
    });

    const [installProfiles, setInstallProfiles] = useState({});
    const [metricView, setMetricView] = useState('percent');
    const [benefitView, setBenefitView] = useState('upper');
    const [energyYear, setEnergyYear] = useState('y10');
    const [investmentYear, setInvestmentYear] = useState('y10');
    const [benefitsYear, setBenefitsYear] = useState('y10');

    const setInstalls = (measure) => {
        let measureValue = outputs.kpis[measure]
        setSelected(prev => {
            prev[measure] = !prev[measure];
            return ({ ...prev });
        });

        let profile = installProfiles
        if (profile.hasOwnProperty(measure)) delete profile[measure];
        else profile[measure] = outputs.costs.yearly_installs.map(year => {
            let x = { name: year.name, installs: Math.round(year.installs * measureValue) }
            return x
        });
        setInstallProfiles(prev => {
            prev = profile;
            return ({ ...profile });
        });
    };

    return (
        <>
            {
                (outputs.kpis && outputs.costs) &&
                <div className='kpi-container flex-card gap-2'>
                    <div className='title-large dark-blue-font'>Energy consumption changes</div>
                    <div className='flex-row gap-1'>
                        <ul className='summary-selector title-large'>
                            <li className={`summary-item title-small ${metricView === 'percent' ? 'summary-selected' : ''}`} onClick={() => setMetricView('percent')}>Percent</li>
                            <li className={`summary-item title-small ${metricView === 'value' ? 'summary-selected' : ''}`} onClick={() => setMetricView('value')}>Value</li>
                        </ul>
                        <div style={{ borderLeft: '1px solid black' }}></div>
                        <ul className='summary-selector title-large'>
                            <li className={`summary-item title-small ${energyYear === 'y10' ? 'summary-selected' : ''}`} onClick={() => setEnergyYear('y10')}>Year 10</li>
                            <li className={`summary-item title-small ${energyYear === 'y25' ? 'summary-selected' : ''}`} onClick={() => setEnergyYear('y25')}>Year 25</li>
                        </ul>
                    </div>
                    <div className='text-small'>Compared to 2023</div>
                    <div className="flex-card gap-2 kpi-container">
                        <div className="kpi-row grid justify-center">
                            <KPI label='Energy saved'
                                value={
                                    metricView === 'percent'
                                        ? energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.total_reduction_percent_year_10) * 100)
                                            : Math.round(Number(outputs.kpis.total_reduction_percent_year_25) * 100)
                                        : energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.energy_saving_total_year_10) / 1000000000)
                                            : Math.round(Number(outputs.kpis.energy_saving_total_year_25) / 1000000000)
                                }
                                metric={metricView === 'percent' ? '%' : 'TWh'}
                                tooltipId='energy_saved'
                                tooltip='Domestic energy (gas, electric, LPG, oil etc) reduction v 2022 baseline.'
                            />
                            <KPI label='Electricity increase'
                                value={
                                    metricView === 'percent'
                                        ? energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.elec_reduction_percent_year_10) * 100)
                                            : Math.round(Number(outputs.kpis.elec_reduction_percent_year_25) * 100)
                                        : energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.energy_saving_elec_year_10) / 1000000000)
                                            : Math.round(Number(outputs.kpis.energy_saving_elec_year_25) / 1000000000)
                                }
                                metric={metricView === 'percent' ? '%' : 'TWh'}
                                tooltipId='elec_increase'
                                tooltip='Electricity and other fuels (non-mains gas) increase v 2022 baseline where electricity represented circa 2/3 of the non-mains gas domestic energy demand.'
                            />
                            <KPI label='Gas saving'
                                value={
                                    metricView === 'percent'
                                        ? energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.gas_reduction_percent_year_10) * 100)
                                            : Math.round(Number(outputs.kpis.gas_reduction_percent_year_25) * 100)
                                        : energyYear === 'y10'
                                            ? Math.round(Number(outputs.kpis.energy_saving_gas_year_10) / 1000000000)
                                            : Math.round(Number(outputs.kpis.energy_saving_gas_year_25) / 1000000000)
                                }
                                metric={metricView === 'percent' ? '%' : 'TWh'}
                                tooltipId='gas_saved'
                                tooltip='Mains gas reduced consumption v 2022 baseline.'
                            />
                        </div>
                        <div className='text-small'>
                            <span
                                data-tooltip-id='counterfactual'
                                data-tooltip-content='Counterfactual – replacing fossil fuel heating with heat pumps (90%) and other non fossil fuel heating (10%) with no investment in energy efficiency and a heat pump SCOP of 2.5.'
                                data-tooltip-place="bottom"
                            >
                                <Tooltip id='counterfactual' style={{ maxWidth: '350px', zIndex: '9999' }} />
                                Compared to <u>counterfactual</u>
                            </span></div>
                        <div className="kpi-row grid justify-center">
                            <KPI label='Winter day elec demand change'
                                value={
                                    energyYear === 'y10'
                                        ? Math.round(Number(outputs.kpis.winter_day_year_10) / 1000000000)
                                        : Math.round(Number(outputs.kpis.winter_day_year_25) / 1000000000)
                                }
                                metric={'GWh'}
                                tooltipId='winter_day'
                                tooltip='Winter day increased electricity (non-mains gas) demand compared the counterfactual.
                                A negative number indicates demand is reduced compared with the counterfactual.'
                            />
                            <KPI label='Storage capacity requirement'
                                value={
                                    energyYear === 'y10'
                                        ? Math.round(Number(outputs.kpis.storage_capacity_year_10) / 1000000000)
                                        : Math.round(Number(outputs.kpis.storage_capacity_year_25) / 1000000000)
                                }
                                metric={'TWh'}
                                tooltipId='storage_cap'
                                tooltip='Illustrative change in storage capacity associated with the change in winter day electrical demand compared with counterfactual.  Calculation based on 14 days of storage capacity.
                                A negative number is a reduction in storage capacity requirement.'
                            />
                            <KPI label='Illustrative generation investment'
                                value={
                                    energyYear === 'y10'
                                        ? '£' + Math.round(Number(outputs.kpis.capacity_generation_year_10) / 1000000000)
                                        : '£' + Math.round(Number(outputs.kpis.capacity_generation_year_25) / 1000000000)
                                }
                                metric={'bn'}
                                tooltipId='gen_investment'
                                tooltip='The additional generation capacity investment required to accommodate the change in winter load (excludes additional grid reinforcement costs) compared with a counterfactual. 
                                If a negative number, then investment is reduced compared with the counterfactual.'
                            />
                        </div>
                    </div>

                    <Separator edge={'left'} />

                    <div className='title-large dark-blue-font slider-group'>Investment requirements</div>
                    <ul className='summary-selector title-large'>
                        <li className={`summary-item title-small ${investmentYear === 'y10' ? 'summary-selected' : ''}`} onClick={() => setInvestmentYear('y10')}>Year 10</li>
                        <li className={`summary-item title-small ${investmentYear === 'y25' ? 'summary-selected' : ''}`} onClick={() => setInvestmentYear('y25')}>Year 25</li>
                    </ul>
                    <div className="flex-card gap-2 kpi-container">
                        <div className="kpi-row grid justify-center">
                            <KPI label='Total investment'
                                value={
                                    investmentYear === 'y10'
                                        ? '£' + Math.round(Number(outputs.kpis.total_spend_year_10) / 1000000000)
                                        : '£' + Math.round(Number(outputs.kpis.total_spend_year_25) / 1000000000)
                                } metric={'bn'}
                                tooltipId='investment_total'
                                tooltip='Total of government and private investment.' />
                            <KPI label='Government investment'
                                value={
                                    investmentYear === 'y10'
                                        ? '£' + Math.round(Number(outputs.kpis.gov_spend_year_10) / 1000000000)
                                        : '£' + Math.round(Number(outputs.kpis.gov_spend_year_25) / 1000000000)
                                } metric={'bn'}
                                tooltipId='investment_gov'
                                tooltip='Grant funding provided by government.' />
                            <KPI label='Private investment'
                                value={
                                    investmentYear === 'y10'
                                        ? '£' + Math.round(Number(outputs.kpis.private_spend_year_10) / 1000000000)
                                        : '£' + Math.round(Number(outputs.kpis.private_spend_year_25) / 1000000000)
                                } metric={'bn'}
                                tooltipId='investment_priv'
                                tooltip='The private investment stimulated by complementary policies and government grants.' />
                        </div>
                        <div className="kpi-row grid justify-center">
                            <KPI label='Net government return'
                                value={
                                    investmentYear === 'y10'
                                        ? '£' + Math.round(-(Number(outputs.kpis.gov_spend_year_10) - Number(outputs.costs.tax_raised_year_10)) / 1000000000)
                                        : '£' + Math.round(-(Number(outputs.kpis.gov_spend_year_25) - Number(outputs.costs.tax_raised_year_25)) / 1000000000)
                                } metric={'bn'}
                                tooltipId='gov_return'
                                tooltip='Illustration of the potential government benefit after grant expenditure is deducted from tax raised assuming a tax to stimulus ratio of 0.49 as a proxi.' />
                        </div>
                    </div>

                    <Separator edge={'right'} />

                    <div className='heading-medium dark-blue-font slider-group'>Benefits</div>
                    {outputs.pwc &&
                        <>
                            <div className='flex-row gap-1'>
                                <ul className='summary-selector title-large'>
                                    <li className={`summary-item title-small ${benefitView === 'upper' ? 'summary-selected' : ''}`} onClick={() => setBenefitView('upper')}>Upper bound</li>
                                    <li className={`summary-item title-small ${benefitView === 'lower' ? 'summary-selected' : ''}`} onClick={() => setBenefitView('lower')}>Lower bound</li>
                                </ul>
                                <div style={{ borderLeft: '1px solid black' }}></div>
                                <ul className='summary-selector title-large'>
                                    <li className={`summary-item title-small ${benefitsYear === 'y10' ? 'summary-selected' : ''}`} onClick={() => setBenefitsYear('y10')}>Year 10</li>
                                    <li className={`summary-item title-small ${benefitsYear === 'y25' ? 'summary-selected' : ''}`} onClick={() => setBenefitsYear('y25')}>Year 25</li>
                                </ul>
                            </div>
                            <div className="flex-card gap-2 kpi-container">
                                <div className="kpi-row grid justify-center">
                                    <KPI label='Annual Householder savings in 2050'
                                        value={
                                            benefitsYear === 'y10'
                                                ? '£' + Math.round(Number(outputs.kpis.total_energy_bill_savings_year_10) / 1000000000)
                                                : '£' + Math.round(Number(outputs.kpis.total_energy_bill_savings_year_25) / 1000000000)
                                        } metric={'bn'}
                                        tooltipId='householder_savings'
                                        tooltip='Annual savings when all measures undertaken (2050 or earlier dependent on scenario).' />
                                    <KPI label='Tax raised'
                                        value={
                                            benefitsYear === 'y10'
                                                ? '£' + Math.round(Number(outputs.costs.tax_raised_year_10) / 1000000000)
                                                : '£' + Math.round(Number(outputs.costs.tax_raised_year_25) / 1000000000)
                                        } metric={'bn'}
                                        tooltipId='tax_raised'
                                        tooltip='Illustration of the potential government benefit from tax raised assuming a tax to stimulus ratio of 0.49 as a proxi.' />
                                    <KPI label='Emissions avoided'
                                        value={
                                            benefitView === 'lower'
                                                ? benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.carbon_abated_low_year_10) / 1000000000)
                                                    : '£' + Math.round(Number(outputs.pwc.carbon_abated_low_year_25) / 1000000000)
                                                : benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.carbon_abated_high_year_10) / 1000000000)
                                                    : '£' + Math.round(Number(outputs.pwc.carbon_abated_high_year_25) / 1000000000)
                                        } metric={'bn/yr'}
                                        tooltipId='emissions_avoided'
                                        tooltip='The welfare benefit to society from avoided carbon abatement in the future.' />
                                </div>
                            </div>
                            <div className="flex-card gap-2 kpi-container">
                                <div className="kpi-row grid justify-center">
                                    <KPI label='Avoided NHS & societal costs'
                                        value={
                                            benefitView === 'lower'
                                                ? benefitsYear === 'y10'
                                                    ? '£' + Math.round((Number(outputs.pwc.excess_cold_low_year_10) + Number(outputs.pwc.excess_damp_low_year_10) + Number(outputs.pwc.excess_heat_low_year_10)) / 1000000000)
                                                    : '£' + Math.round((Number(outputs.pwc.excess_cold_low_year_25) + Number(outputs.pwc.excess_damp_low_year_25) + Number(outputs.pwc.excess_heat_low_year_25)) / 1000000000)
                                                : benefitsYear === 'y10'
                                                    ? '£' + Math.round((Number(outputs.pwc.excess_cold_high_year_10) + Number(outputs.pwc.excess_damp_high_year_10) + Number(outputs.pwc.excess_heat_high_year_10)) / 1000000000)
                                                    : '£' + Math.round((Number(outputs.pwc.excess_cold_high_year_25) + Number(outputs.pwc.excess_damp_high_year_25) + Number(outputs.pwc.excess_heat_high_year_25)) / 1000000000)
                                        } metric={'bn/yr'}
                                        tooltipId='nhs_avoided'
                                        tooltip='Avoided NHS and societal costs of cold home avoidance, dampness avoidance and overheating avoidance by year 10.
                                    The welfare benefit to society from avoided health and informal care costs and lost economic output resulting from poor health outcomes from: excessively low indoor temperatures in homes during winter months, excessively damp conditions in homes during winter months, and excessively high indoor temperatures in homes during summer months.'/>
                                    <KPI label='Clean air'
                                        value={
                                            benefitView === 'lower'
                                                ? benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.avoided_air_pollution_low_year_10) / 100000000)
                                                    : '£' + Math.round(Number(outputs.pwc.avoided_air_pollution_low_year_25) / 100000000)
                                                : benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.avoided_air_pollution_high_year_10) / 100000000)
                                                    : '£' + Math.round(Number(outputs.pwc.avoided_air_pollution_high_year_25) / 100000000)
                                        } metric={'m/yr'}
                                        tooltipId='clean_air'
                                        tooltip='The welfare benefit to society from the avoided costs to human health from air pollution.' />
                                    <KPI label='Home comfort'
                                        value={
                                            benefitView === 'lower'
                                                ? benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.improved_home_comfort_low_year_10) / 1000000)
                                                    : '£' + Math.round(Number(outputs.pwc.improved_home_comfort_low_year_25) / 1000000)
                                                : benefitsYear === 'y10'
                                                    ? '£' + Math.round(Number(outputs.pwc.improved_home_comfort_high_year_10) / 1000000)
                                                    : '£' + Math.round(Number(outputs.pwc.improved_home_comfort_high_year_25) / 1000000)
                                        } metric={'m/yr'}
                                        tooltipId='home_comfort'
                                        tooltip='The welfare benefit to society from additional income to spend on energy use as a result of lower energy bills.' />
                                </div>
                            </div>
                        </>
                    }

                    <Separator edge={'left'} />

                    <div className='heading-medium dark-blue-font slider-group'>Timelines</div>
                    <ul className='summary-selector title-large'>
                        <li className={`summary-item title-large ${text === 'costs' ? 'summary-selected' : ''}`} onClick={() => setText('costs')}>Spend profile</li>
                        <li className={`summary-item title-large ${text === 'installs' ? 'summary-selected' : ''}`} onClick={() => setText('installs')}>Install profile</li>
                    </ul>
                    <div className="grid justify-center slider-group" style={{ display: text === 'costs' ? 'grid' : 'none' }}>
                        <CustomLineChart outputs={outputs.costs.yearly_costs} type="yearly_costs" />
                    </div>
                    <div className="grid-split-2 justify-center slider-group" style={{ display: text === 'installs' ? 'grid' : 'none' }}>
                        <CustomLineChart outputs={installProfiles} type="yearly_installs" profile={outputs.costs.yearly_installs} />
                        <div className="flex-center" style={{ alignItems: "flex-start" }}>
                            <div>
                                <fieldset>
                                    <legend>Select countries to target</legend>
                                    {
                                        structure.measuresList && structure.measuresList.map(measure => {
                                            return (
                                                <div key={measure.name + '_plot'} >
                                                    <input type="checkbox" checked={selected[measure.name]} name={measure.name} onChange={() => setInstalls(measure.name)} />
                                                    <label htmlFor="html" onChange={() => setInstalls(measure.name)} >{measure.label}</label><br />
                                                </div>
                                            )
                                        })
                                    }
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default KPIs;