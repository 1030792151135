import React, { useState, createContext } from 'react';

export const DataContext = createContext();
export const DataContextProvider = props => {
    const [data, setData] = useState({
        metaData: {
            name: 'scenario_1',
            label: 'Energy Efficiency (only)',
            description: 'Energy efficiency measures applied - no heating.'
        },
        measuresValues: {
            uptake_cwi: 100,
            uptake_ewi: 50,
            uptake_pwi: 50,
            uptake_lif: 100,
            uptake_litu: 100,
            uptake_fri: 100,
            uptake_hp: 0,
            uptake_olch: 0,
            uptake_pv: 20,
            uptake_sc: 100,
            uptake_gl: 80,
            uptake_st: 0,
            uptake_bat: 0,
            uptake_light: 100,
            uptake_floor: 60,
            uptake_rmi: 70,
            uptake_vent: 30,
            uptake_overheat: 0,
            uptake_water: 50,
        },
        otherCostsValues: {
            other_costs_training: 0.5,
            other_costs_capacity_expansion: 5,
            other_costs_capacity_development: 0.5,
            other_costs_r_and_d: 0,
            other_costs_comms: 1.25,
        },
        countriesValues: {
            england: true,
            wales: true,
            scotland: true,
            northern_ireland: true,
        },
        deliveryYearsValues: {
            owner_occupied_fuel_poor_asset_poor_delivery_years: 10,
            owner_occupied_fuel_poor_asset_rich_delivery_years: 10,
            owner_occupied_near_fuel_poor_asset_poor_delivery_years: 10,
            owner_occupied_near_fuel_poor_asset_rich_delivery_years: 10,
            owner_occupied_non_fuel_poor_asset_poor_delivery_years: 25,
            owner_occupied_non_fuel_poor_asset_rich_delivery_years: 25,
            rental_private_fuel_poor_delivery_years: 10,
            rental_private_near_fuel_poor_delivery_years: 10,
            rental_private_non_fuel_poor_delivery_years: 15,
            rental_social_fuel_poor_delivery_years: 10,
            rental_social_near_fuel_poor_delivery_years: 10,
            rental_social_non_fuel_poor_delivery_years: 10,
        },
        fundingYearsValues: {
            owner_occupied_fuel_poor_asset_poor_funding_years: 10,
            owner_occupied_fuel_poor_asset_rich_funding_years: 10,
            owner_occupied_near_fuel_poor_asset_poor_funding_years: 10,
            owner_occupied_near_fuel_poor_asset_rich_funding_years: 10,
            owner_occupied_non_fuel_poor_asset_poor_funding_years: 20,
            owner_occupied_non_fuel_poor_asset_rich_funding_years: 20,
            rental_private_fuel_poor_funding_years: 10,
            rental_private_near_fuel_poor_funding_years: 10,
            rental_private_non_fuel_poor_funding_years: 10,
            rental_social_fuel_poor_funding_years: 10,
            rental_social_near_fuel_poor_funding_years: 10,
            rental_social_non_fuel_poor_funding_years: 10,
        },
        interventionsInputs: {
            rental_social_fuel_poor: 80,
            rental_social_near_fuel_poor: 80,
            rental_social_non_fuel_poor: 80,
            rental_private_fuel_poor: 50,
            rental_private_near_fuel_poor: 50,
            rental_private_non_fuel_poor: 25,
            owner_occupied_fuel_poor_asset_poor: 80,
            owner_occupied_fuel_poor_asset_rich: 25,
            owner_occupied_near_fuel_poor_asset_poor: 80,
            owner_occupied_near_fuel_poor_asset_rich: 25,
            owner_occupied_non_fuel_poor_asset_poor: 50,
            owner_occupied_non_fuel_poor_asset_rich: 10,
        },
        interventionsValues: {
            rental_social_fuel_poor_d: 80,
            rental_social_fuel_poor_e: 80,
            rental_social_fuel_poor_f: 80,
            rental_social_fuel_poor_g: 80,
            rental_social_near_fuel_poor_d: 80,
            rental_social_near_fuel_poor_e: 80,
            rental_social_near_fuel_poor_f: 80,
            rental_social_near_fuel_poor_g: 80,
            rental_social_non_fuel_poor_a: 80,
            rental_social_non_fuel_poor_b: 80,
            rental_social_non_fuel_poor_c: 80,
            rental_social_non_fuel_poor_d: 80,
            rental_social_non_fuel_poor_e: 80,
            rental_social_non_fuel_poor_f: 80,
            rental_social_non_fuel_poor_g: 80,
            rental_private_fuel_poor_d: 50,
            rental_private_fuel_poor_e: 50,
            rental_private_fuel_poor_f: 50,
            rental_private_fuel_poor_g: 50,
            rental_private_near_fuel_poor_d: 50,
            rental_private_near_fuel_poor_e: 50,
            rental_private_near_fuel_poor_f: 50,
            rental_private_near_fuel_poor_g: 50,
            rental_private_non_fuel_poor_a: 25,
            rental_private_non_fuel_poor_b: 25,
            rental_private_non_fuel_poor_c: 25,
            rental_private_non_fuel_poor_d: 25,
            rental_private_non_fuel_poor_e: 25,
            rental_private_non_fuel_poor_f: 25,
            rental_private_non_fuel_poor_g: 25,
            owner_occupied_fuel_poor_asset_poor_d: 80,
            owner_occupied_fuel_poor_asset_poor_e: 80,
            owner_occupied_fuel_poor_asset_poor_f: 80,
            owner_occupied_fuel_poor_asset_poor_g: 80,
            owner_occupied_fuel_poor_asset_rich_d: 25,
            owner_occupied_fuel_poor_asset_rich_e: 25,
            owner_occupied_fuel_poor_asset_rich_f: 25,
            owner_occupied_fuel_poor_asset_rich_g: 25,
            owner_occupied_near_fuel_poor_asset_poor_d: 80,
            owner_occupied_near_fuel_poor_asset_poor_e: 80,
            owner_occupied_near_fuel_poor_asset_poor_f: 80,
            owner_occupied_near_fuel_poor_asset_poor_g: 80,
            owner_occupied_near_fuel_poor_asset_rich_d: 25,
            owner_occupied_near_fuel_poor_asset_rich_e: 25,
            owner_occupied_near_fuel_poor_asset_rich_f: 25,
            owner_occupied_near_fuel_poor_asset_rich_g: 25,
            owner_occupied_non_fuel_poor_asset_poor_a: 50,
            owner_occupied_non_fuel_poor_asset_poor_b: 50,
            owner_occupied_non_fuel_poor_asset_poor_c: 50,
            owner_occupied_non_fuel_poor_asset_poor_d: 50,
            owner_occupied_non_fuel_poor_asset_poor_e: 50,
            owner_occupied_non_fuel_poor_asset_poor_f: 50,
            owner_occupied_non_fuel_poor_asset_poor_g: 50,
            owner_occupied_non_fuel_poor_asset_rich_a: 10,
            owner_occupied_non_fuel_poor_asset_rich_b: 10,
            owner_occupied_non_fuel_poor_asset_rich_c: 10,
            owner_occupied_non_fuel_poor_asset_rich_d: 10,
            owner_occupied_non_fuel_poor_asset_rich_e: 10,
            owner_occupied_non_fuel_poor_asset_rich_f: 10,
            owner_occupied_non_fuel_poor_asset_rich_g: 10,
        },
        hpRatio: 'hp_0_olch_0',
        comments: {
            rental_social_fuel_poor: '',
            rental_social_near_fuel_poor: '',
            rental_social_non_fuel_poor: '',
            rental_private_fuel_poor: '',
            rental_private_near_fuel_poor: '',
            rental_private_non_fuel_poor: '',
            owner_occupied_fuel_poor_asset_poor: '',
            owner_occupied_fuel_poor_asset_rich: '',
            owner_occupied_near_fuel_poor_asset_poor: '',
            owner_occupied_near_fuel_poor_asset_rich: '',
            owner_occupied_non_fuel_poor_asset_poor: '',
            owner_occupied_non_fuel_poor_asset_rich: ''
        },
        assumptions: {
            cost_reduction_1: -10,
            cost_reduction_2: 0,
            cost_reduction_3: 0,
            cost_reduction_4: 0,
            cost_reduction_5: 0,
        },
        electricityPrice: 0.231,
        electricityStandingCharge: 0.53,
        gasPrice: 0.07,
        gasStandingCharge: 0.30,
        savingsAssumption: 'upper',
        scopAssumption: 'high',
    })

    return (
        <DataContext.Provider value={[data, setData]}>
            {props.children}
        </DataContext.Provider>
    )

}