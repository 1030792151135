import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SelectInput = ({ label, name, value, options, handler }) => {
    return (
        <FormControl className='input-container' style={{minWidth: 250}}>
            <InputLabel className='input-select-label' id={`${name}-label`}>{label}</InputLabel>
            <Select
                labelId={`${name}-label`}
                id={`${name}-slider`}
                value={value}
                label={label}
                className='input-select'
                onChange={handler}
            >
                {options.map((option) => (
                    <MenuItem className='input-select-option' key={option.value} value={option.value} name={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectInput