import React, { useState, createContext } from 'react';

export const StructureContext = createContext();
export const StructureContextProvider = props => {
    const [structure, setStructure] = useState({
        measuresList: [
            { name: 'uptake_cwi', label: 'Cavity wall insulation', group: 'insulation', tooltip: 'Simple cavity walls suitable for insulation. Saves 13% of energy use or if ‘enhanced’ 24%.' },
            { name: 'uptake_ewi', label: 'Solid wall insulation', group: 'insulation', tooltip: 'Hard to treat cavity walls and solid walls suitable for insulation. Saves 38% of energy use.' },
            { name: 'uptake_pwi', label: 'Party wall insulation', group: 'insulation' },
            { name: 'uptake_lif', label: 'Loft insulation', group: 'insulation', tooltip: 'Saves 4% or if "enhanced" 19%.' },
            { name: 'uptake_litu', label: 'Loft insulation top-up', group: 'insulation' },
            { name: 'uptake_fri', label: 'Flat roof insulation', group: 'insulation', tooltip: 'No costs applied as assumed to be driven by existing building regulations.' },
            { name: 'uptake_pv', label: 'Photovoltaic panels', group: 'low_carbon' },
            { name: 'uptake_sc', label: 'Smart controls', group: 'low_carbon' },
            { name: 'uptake_gl', label: 'Double glazing', group: 'insulation', tooltip: 'No costs applied as assumed to be undertaken as a window replacement.' },
            // { name: 'uptake_bat', label: 'Batteries', group: 'low_carbon' },
            { name: 'uptake_light', label: 'Lighting' },
            { name: 'uptake_floor', label: 'Floor insulation', group: 'insulation', tooltip: 'Assumes suspended wooden floor insulation.' },
            { name: 'uptake_rmi', label: 'Repairs & maintenance', group: 'other', tooltip: 'Applies an allowance of £500 to all fuel poor and near fuel poor owner occupied homes and private rented homes for general maintenance associated with energy efficiency measures such as gutter maintenance, re-pointing etc.' },
            { name: 'uptake_vent', label: 'Ventilation', group: 'other', tooltip: 'Applies a £100 allowance for ventilation measures' },
            { name: 'uptake_overheat', label: 'Overheating mitigation', group: 'other', tooltip: 'Applies a £5,000 allowance for overheating mitigation measures' },
            { name: 'uptake_water', label: 'Water stress mitigation', group: 'other', tooltip: 'Applies a £100 allowance for basic water saving measures' },
        ],
        heatingOptions: [
            { value: 'hp_10_olch_90', label: '10% heat pumps, 90% other low carbon heating' },
            { value: 'hp_50_olch_50', label: '50% heat pumps, 50% other low carbon heating' },
            { value: 'hp_70_olch_30', label: '70% heat pumps, 30% other low carbon heating' },
            { value: 'hp_80_olch_20', label: '80% heat pumps, 20% other low carbon heating' },
            { value: 'hp_90_olch_10', label: '90% heat pumps, 10% other low carbon heating' },
            { value: 'hp_0_olch_0', label: 'No heat pumps or other low carbon heating' },
        ],
        otherCosts: [
            { name: 'other_costs_training', label: 'Training funding', symbol: '£' },
            { name: 'other_costs_capacity_expansion', label: 'Capacity expansion', symbol: '£' },
            { name: 'other_costs_capacity_development', label: 'Capacity development', symbol: '£' },
            { name: 'other_costs_r_and_d', label: 'R&D funding', symbol: '£' },
            { name: 'other_costs_comms', label: 'Comms & publicity', symbol: '£' },
        ],
        countries: {
            uk: 'UK',
            england: 'England',
            wales: 'Wales',
            scotland: 'Scotland',
        },
        // interventionsList: [
        //     {
        //         name: 'rental_social',
        //         label: 'Rental (social)',
        //         branches: [
        //             {
        //                 name: 'rental_social_fuel_poor',
        //                 label: 'Fuel poor',
        //                 inputs: [
        //                     { name: 'rental_social_fuel_poor_d', inputs: ['rental_social_fuel_poor_asset_poor_d', 'rental_social_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_social_fuel_poor_e', inputs: ['rental_social_fuel_poor_asset_poor_e', 'rental_social_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_social_fuel_poor_f', inputs: ['rental_social_fuel_poor_asset_poor_f', 'rental_social_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_social_fuel_poor_g', inputs: ['rental_social_fuel_poor_asset_poor_g', 'rental_social_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_social_fuel_poor_delivery_years', inputs: ['rental_social_fuel_poor_asset_poor', 'rental_social_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_social_fuel_poor_funding_years', inputs: ['rental_social_fuel_poor_asset_poor', 'rental_social_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             },
        //             {
        //                 name: 'rental_social_near_fuel_poor',
        //                 label: 'Near fuel poor',
        //                 inputs: [
        //                     { name: 'rental_social_near_fuel_poor_d', inputs: ['rental_social_near_fuel_poor_asset_poor_d', 'rental_social_near_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_social_near_fuel_poor_e', inputs: ['rental_social_near_fuel_poor_asset_poor_e', 'rental_social_near_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_social_near_fuel_poor_f', inputs: ['rental_social_near_fuel_poor_asset_poor_f', 'rental_social_near_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_social_near_fuel_poor_g', inputs: ['rental_social_near_fuel_poor_asset_poor_g', 'rental_social_near_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_social_near_fuel_poor_delivery_years', inputs: ['rental_social_near_fuel_poor_asset_poor', 'rental_social_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_social_near_fuel_poor_funding_years', inputs: ['rental_social_near_fuel_poor_asset_poor', 'rental_social_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             },
        //             {
        //                 name: 'rental_social_non_fuel_poor',
        //                 label: 'Non fuel poor',
        //                 inputs: [
        //                     { name: 'rental_social_non_fuel_poor_a', inputs: ['rental_social_non_fuel_poor_asset_poor_a', 'rental_social_non_fuel_poor_asset_rich_a'], inputType: 'gov_support', label: 'Band A', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_b', inputs: ['rental_social_non_fuel_poor_asset_poor_b', 'rental_social_non_fuel_poor_asset_rich_b'], inputType: 'gov_support', label: 'Band B', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_c', inputs: ['rental_social_non_fuel_poor_asset_poor_c', 'rental_social_non_fuel_poor_asset_rich_c'], inputType: 'gov_support', label: 'Band C', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_d', inputs: ['rental_social_non_fuel_poor_asset_poor_d', 'rental_social_non_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_e', inputs: ['rental_social_non_fuel_poor_asset_poor_e', 'rental_social_non_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_f', inputs: ['rental_social_non_fuel_poor_asset_poor_f', 'rental_social_non_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_g', inputs: ['rental_social_non_fuel_poor_asset_poor_g', 'rental_social_non_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_social_non_fuel_poor_delivery_years', inputs: ['rental_social_non_fuel_poor_asset_poor', 'rental_social_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_social_non_fuel_poor_funding_years', inputs: ['rental_social_non_fuel_poor_asset_poor', 'rental_social_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         name: 'rental_private',
        //         label: 'Rental (private)',
        //         branches: [
        //             {
        //                 name: 'rental_private_fuel_poor',
        //                 label: 'Fuel poor',
        //                 inputs: [
        //                     { name: 'rental_private_fuel_poor_d', inputs: ['rental_private_fuel_poor_asset_poor_d', 'rental_private_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_private_fuel_poor_e', inputs: ['rental_private_fuel_poor_asset_poor_e', 'rental_private_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_private_fuel_poor_f', inputs: ['rental_private_fuel_poor_asset_poor_f', 'rental_private_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_private_fuel_poor_g', inputs: ['rental_private_fuel_poor_asset_poor_g', 'rental_private_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_private_fuel_poor_delivery_years', inputs: ['rental_private_fuel_poor_asset_poor', 'rental_private_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_private_fuel_poor_funding_years', inputs: ['rental_private_fuel_poor_asset_poor', 'rental_private_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             },
        //             {
        //                 name: 'rental_private_near_fuel_poor',
        //                 label: 'Near fuel poor',
        //                 inputs: [
        //                     { name: 'rental_private_near_fuel_poor_d', inputs: ['rental_private_near_fuel_poor_asset_poor_d', 'rental_private_near_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_private_near_fuel_poor_e', inputs: ['rental_private_near_fuel_poor_asset_poor_e', 'rental_private_near_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_private_near_fuel_poor_f', inputs: ['rental_private_near_fuel_poor_asset_poor_f', 'rental_private_near_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_private_near_fuel_poor_g', inputs: ['rental_private_near_fuel_poor_asset_poor_g', 'rental_private_near_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_private_near_fuel_poor_delivery_years', inputs: ['rental_private_near_fuel_poor_asset_poor', 'rental_private_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_private_near_fuel_poor_funding_years', inputs: ['rental_private_near_fuel_poor_asset_poor', 'rental_private_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             },
        //             {
        //                 name: 'rental_private_non_fuel_poor',
        //                 label: 'Non fuel poor',
        //                 inputs: [
        //                     { name: 'rental_private_non_fuel_poor_a', inputs: ['rental_private_non_fuel_poor_asset_poor_a', 'rental_private_non_fuel_poor_asset_rich_a'], inputType: 'gov_support', label: 'Band A', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_b', inputs: ['rental_private_non_fuel_poor_asset_poor_b', 'rental_private_non_fuel_poor_asset_rich_b'], inputType: 'gov_support', label: 'Band B', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_c', inputs: ['rental_private_non_fuel_poor_asset_poor_c', 'rental_private_non_fuel_poor_asset_rich_c'], inputType: 'gov_support', label: 'Band C', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_d', inputs: ['rental_private_non_fuel_poor_asset_poor_d', 'rental_private_non_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_e', inputs: ['rental_private_non_fuel_poor_asset_poor_e', 'rental_private_non_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_f', inputs: ['rental_private_non_fuel_poor_asset_poor_f', 'rental_private_non_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_g', inputs: ['rental_private_non_fuel_poor_asset_poor_g', 'rental_private_non_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                     { name: 'rental_private_non_fuel_poor_delivery_years', inputs: ['rental_private_non_fuel_poor_asset_poor', 'rental_private_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                     { name: 'rental_private_non_fuel_poor_funding_years', inputs: ['rental_private_non_fuel_poor_asset_poor', 'rental_private_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         name: 'owner_occupied',
        //         label: 'Owner occupied',
        //         branches: [
        //             {
        //                 name: 'owner_occupied_fuel_poor',
        //                 label: 'Fuel poor',
        //                 branches: [
        //                     {
        //                         name: 'owner_occupied_fuel_poor_asset_poor',
        //                         label: 'Asset poor',
        //                         inputs: [
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_d', inputs: ['owner_occupied_fuel_poor_asset_poor_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_e', inputs: ['owner_occupied_fuel_poor_asset_poor_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_f', inputs: ['owner_occupied_fuel_poor_asset_poor_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_g', inputs: ['owner_occupied_fuel_poor_asset_poor_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     },
        //                     {
        //                         name: 'owner_occupied_fuel_poor_asset_rich',
        //                         label: 'Asset rich',
        //                         inputs: [
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_d', inputs: ['owner_occupied_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_e', inputs: ['owner_occupied_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_f', inputs: ['owner_occupied_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_g', inputs: ['owner_occupied_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     },
        //                 ]
        //             },
        //             {
        //                 name: 'owner_occupied_near_fuel_poor',
        //                 label: 'Near fuel poor',
        //                 branches: [
        //                     {
        //                         name: 'owner_occupied_near_fuel_poor_asset_poor',
        //                         label: 'Asset poor',
        //                         inputs: [
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_d', inputs: ['owner_occupied_near_fuel_poor_asset_poor_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_e', inputs: ['owner_occupied_near_fuel_poor_asset_poor_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_f', inputs: ['owner_occupied_near_fuel_poor_asset_poor_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_g', inputs: ['owner_occupied_near_fuel_poor_asset_poor_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_near_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_near_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     },
        //                     {
        //                         name: 'owner_occupied_near_fuel_poor_asset_rich',
        //                         label: 'Asset rich',
        //                         inputs: [
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_d', inputs: ['owner_occupied_near_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_e', inputs: ['owner_occupied_near_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_f', inputs: ['owner_occupied_near_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_g', inputs: ['owner_occupied_near_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_near_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     }
        //                 ]
        //             },
        //             {
        //                 name: 'owner_occupied_non_fuel_poor',
        //                 label: 'Non fuel poor',
        //                 branches: [
        //                     {
        //                         name: 'owner_occupied_non_fuel_poor_asset_poor',
        //                         label: 'Asset poor',
        //                         inputs: [
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_a', inputs: ['owner_occupied_non_fuel_poor_asset_poor_a'], inputType: 'gov_support', label: 'Band A', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_b', inputs: ['owner_occupied_non_fuel_poor_asset_poor_b'], inputType: 'gov_support', label: 'Band B', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_c', inputs: ['owner_occupied_non_fuel_poor_asset_poor_c'], inputType: 'gov_support', label: 'Band C', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_d', inputs: ['owner_occupied_non_fuel_poor_asset_poor_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_e', inputs: ['owner_occupied_non_fuel_poor_asset_poor_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_f', inputs: ['owner_occupied_non_fuel_poor_asset_poor_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_g', inputs: ['owner_occupied_non_fuel_poor_asset_poor_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_non_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_non_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     },
        //                     {
        //                         name: 'owner_occupied_non_fuel_poor_asset_rich',
        //                         label: 'Asset rich',
        //                         inputs: [
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_a', inputs: ['owner_occupied_non_fuel_poor_asset_rich_a'], inputType: 'gov_support', label: 'Band A', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_b', inputs: ['owner_occupied_non_fuel_poor_asset_rich_b'], inputType: 'gov_support', label: 'Band B', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_c', inputs: ['owner_occupied_non_fuel_poor_asset_rich_c'], inputType: 'gov_support', label: 'Band C', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_d', inputs: ['owner_occupied_non_fuel_poor_asset_rich_d'], inputType: 'gov_support', label: 'Band D', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_e', inputs: ['owner_occupied_non_fuel_poor_asset_rich_e'], inputType: 'gov_support', label: 'Band E', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_f', inputs: ['owner_occupied_non_fuel_poor_asset_rich_f'], inputType: 'gov_support', label: 'Band F', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_g', inputs: ['owner_occupied_non_fuel_poor_asset_rich_g'], inputType: 'gov_support', label: 'Band G', symbol: '%' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
        //                             { name: 'owner_occupied_non_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
        //                         ]
        //                     }
        //                 ]
        //             }
        //         ]
        //     }
        // ],
        interventionsList: [
            {
                name: 'rental_social',
                label: 'Rental (social)',
                branches: [
                    {
                        name: 'rental_social_fuel_poor',
                        label: 'Fuel poor',
                        inputs: [
                            { name: 'rental_social_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_social_fuel_poor_delivery_years', inputs: ['rental_social_fuel_poor_asset_poor', 'rental_social_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_social_fuel_poor_funding_years', inputs: ['rental_social_fuel_poor_asset_poor', 'rental_social_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    },
                    {
                        name: 'rental_social_near_fuel_poor',
                        label: 'Near fuel poor',
                        inputs: [
                            { name: 'rental_social_near_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_social_near_fuel_poor_delivery_years', inputs: ['rental_social_near_fuel_poor_asset_poor', 'rental_social_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_social_near_fuel_poor_funding_years', inputs: ['rental_social_near_fuel_poor_asset_poor', 'rental_social_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    },
                    {
                        name: 'rental_social_non_fuel_poor',
                        label: 'Non fuel poor',
                        inputs: [
                            { name: 'rental_social_non_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_social_non_fuel_poor_delivery_years', inputs: ['rental_social_non_fuel_poor_asset_poor', 'rental_social_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_social_non_fuel_poor_funding_years', inputs: ['rental_social_non_fuel_poor_asset_poor', 'rental_social_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    }
                ]
            },
            {
                name: 'rental_private',
                label: 'Rental (private)',
                branches: [
                    {
                        name: 'rental_private_fuel_poor',
                        label: 'Fuel poor',
                        inputs: [
                            { name: 'rental_private_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_private_fuel_poor_delivery_years', inputs: ['rental_private_fuel_poor_asset_poor', 'rental_private_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_private_fuel_poor_funding_years', inputs: ['rental_private_fuel_poor_asset_poor', 'rental_private_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    },
                    {
                        name: 'rental_private_near_fuel_poor',
                        label: 'Near fuel poor',
                        inputs: [
                            { name: 'rental_private_near_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_private_near_fuel_poor_delivery_years', inputs: ['rental_private_near_fuel_poor_asset_poor', 'rental_private_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_private_near_fuel_poor_funding_years', inputs: ['rental_private_near_fuel_poor_asset_poor', 'rental_private_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    },
                    {
                        name: 'rental_private_non_fuel_poor',
                        label: 'Non fuel poor',
                        inputs: [
                            { name: 'rental_private_non_fuel_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                            { name: 'rental_private_non_fuel_poor_delivery_years', inputs: ['rental_private_non_fuel_poor_asset_poor', 'rental_private_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                            { name: 'rental_private_non_fuel_poor_funding_years', inputs: ['rental_private_non_fuel_poor_asset_poor', 'rental_private_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                        ]
                    }
                ]
            },
            {
                name: 'owner_occupied',
                label: 'Owner occupied',
                branches: [
                    {
                        name: 'owner_occupied_fuel_poor',
                        label: 'Fuel poor',
                        branches: [
                            {
                                name: 'owner_occupied_fuel_poor_asset_poor',
                                label: 'Asset poor',
                                inputs: [
                                    { name: 'owner_occupied_fuel_poor_asset_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            },
                            {
                                name: 'owner_occupied_fuel_poor_asset_rich',
                                label: 'Asset rich',
                                inputs: [
                                    { name: 'owner_occupied_fuel_poor_asset_rich', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            },
                        ]
                    },
                    {
                        name: 'owner_occupied_near_fuel_poor',
                        label: 'Near fuel poor',
                        branches: [
                            {
                                name: 'owner_occupied_near_fuel_poor_asset_poor',
                                label: 'Asset poor',
                                inputs: [
                                    { name: 'owner_occupied_near_fuel_poor_asset_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_near_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_near_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_near_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_near_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            },
                            {
                                name: 'owner_occupied_near_fuel_poor_asset_rich',
                                label: 'Asset rich',
                                inputs: [
                                    { name: 'owner_occupied_near_fuel_poor_asset_rich', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_near_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_near_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_near_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_near_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            }
                        ]
                    },
                    {
                        name: 'owner_occupied_non_fuel_poor',
                        label: 'Non fuel poor',
                        branches: [
                            {
                                name: 'owner_occupied_non_fuel_poor_asset_poor',
                                label: 'Asset poor',
                                inputs: [
                                    { name: 'owner_occupied_non_fuel_poor_asset_poor', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_non_fuel_poor_asset_poor_delivery_years', inputs: ['owner_occupied_non_fuel_poor_asset_poor'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_non_fuel_poor_asset_poor_funding_years', inputs: ['owner_occupied_non_fuel_poor_asset_poor'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            },
                            {
                                name: 'owner_occupied_non_fuel_poor_asset_rich',
                                label: 'Asset rich',
                                inputs: [
                                    { name: 'owner_occupied_non_fuel_poor_asset_rich', inputType: 'gov_support', label: 'Gvt financial support', symbol: '%' },
                                    { name: 'owner_occupied_non_fuel_poor_asset_rich_delivery_years', inputs: ['owner_occupied_non_fuel_poor_asset_rich'], inputType: 'delivery_years', label: 'Completed within', symbol: 'years' },
                                    { name: 'owner_occupied_non_fuel_poor_asset_rich_funding_years', inputs: ['owner_occupied_non_fuel_poor_asset_rich'], inputType: 'funding_years', label: 'Funded for', symbol: 'years' },
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        assumptions: [
            { name: 'cost_reduction_1', label: 'Cost reduction year 1', },
            { name: 'cost_reduction_2', label: 'Cost reduction year 2', },
            { name: 'cost_reduction_3', label: 'Cost reduction year 3', },
            { name: 'cost_reduction_4', label: 'Cost reduction year 4', },
            { name: 'cost_reduction_5', label: 'Cost reduction year 5', },
        ],
        savingsOptions: [
            { value: 'median', label: 'Median' },
            { value: 'upper', label: 'Upper quartile' },
        ],
        scopOptions: [
            { value: 'high', label: '2.7' },
            { value: 'low', label: '2.5' },
        ],
    });

    return (
        <StructureContext.Provider value={[structure, setStructure]}>
            {props.children}
        </StructureContext.Provider>
    )
}