import './styling/App.css';
import './styling/Layout.css';
import './styling/Theme.css';
import './styling/UKGBC.css';

import { useEffect, useContext } from 'react';

import Header from './components/Layout/Header';
import Summary from './components/Summary';
import Accordion from './components/Layout/Accordion.component';
import Measures from './components/Measures';
import Interventions from './components/Interventions';
import Geography from './components/Geography';
import KPIs from './components/KPIs';
import Footer from './components/Layout/footer';
import Close from './components/Close';

import { DataContext } from './context/DataContext';
import { OutputContext } from './context/OutputContext';
import { dataUpdates } from './utils/processData';

function App() {
	const [data] = useContext(DataContext);
	const [outputs, setOutputs] = useContext(OutputContext);

	useEffect(() => {
		dataUpdates.processData(data, outputs, setOutputs);
	}, []);

	return (
		<div className="app">
			<Header />
			<main className="container flex-card flex-gap-3">
				<Summary />
				<div className="flex-card">
					<Accordion component={<Measures />} title='Home retrofit selection' />
					<Accordion component={<Interventions />} title='Financial support' />
					<Accordion component={<Geography />} title='Assumptions' />
				</div>
				<KPIs />
				<Close />
			</main>
			<Footer />
		</div>
	);
}

export default App;
