import React, { useState, useEffect, useContext } from 'react';
import NumberInput from '../numeric.component';
import TextArea from '../textArea.component';
import './tree.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../../../../context/DataContext';
import { StructureContext } from '../../../../context/StructureContext';
import { OutputContext } from '../../../../context/OutputContext';
import { dataUpdates } from '../../../../utils/processData';

const Tree = () => {
    const [data, setData] = useContext(DataContext);
    const [structure] = useContext(StructureContext);
    const [outputs, setOutputs] = useContext(OutputContext);
    const [treeBranches, setTreeBranches] = useState({});

    const govInterventions = {
        rental_social_fuel_poor: [
            'rental_social_fuel_poor_d',
            'rental_social_fuel_poor_e',
            'rental_social_fuel_poor_f',
            'rental_social_fuel_poor_g'
        ],
        rental_social_near_fuel_poor: [
            'rental_social_near_fuel_poor_d',
            'rental_social_near_fuel_poor_e',
            'rental_social_near_fuel_poor_f',
            'rental_social_near_fuel_poor_g'
        ],
        rental_social_non_fuel_poor: [
            'rental_social_non_fuel_poor_a',
            'rental_social_non_fuel_poor_b',
            'rental_social_non_fuel_poor_c',
            'rental_social_non_fuel_poor_d',
            'rental_social_non_fuel_poor_e',
            'rental_social_non_fuel_poor_f',
            'rental_social_non_fuel_poor_g'
        ],
        rental_private_fuel_poor: [
            'rental_private_fuel_poor_d',
            'rental_private_fuel_poor_e',
            'rental_private_fuel_poor_f',
            'rental_private_fuel_poor_g'
        ],
        rental_private_near_fuel_poor: [
            'rental_private_near_fuel_poor_d',
            'rental_private_near_fuel_poor_e',
            'rental_private_near_fuel_poor_f',
            'rental_private_near_fuel_poor_g'
        ],
        rental_private_non_fuel_poor: [
            'rental_private_non_fuel_poor_a',
            'rental_private_non_fuel_poor_b',
            'rental_private_non_fuel_poor_c',
            'rental_private_non_fuel_poor_d',
            'rental_private_non_fuel_poor_e',
            'rental_private_non_fuel_poor_f',
            'rental_private_non_fuel_poor_g'
        ],
        owner_occupied_fuel_poor_asset_poor: [
            'owner_occupied_fuel_poor_asset_poor_d',
            'owner_occupied_fuel_poor_asset_poor_e',
            'owner_occupied_fuel_poor_asset_poor_f',
            'owner_occupied_fuel_poor_asset_poor_g'
        ],
        owner_occupied_fuel_poor_asset_rich: [
            'owner_occupied_fuel_poor_asset_rich_d',
            'owner_occupied_fuel_poor_asset_rich_e',
            'owner_occupied_fuel_poor_asset_rich_f',
            'owner_occupied_fuel_poor_asset_rich_g'
        ],
        owner_occupied_near_fuel_poor_asset_poor: [
            'owner_occupied_near_fuel_poor_asset_poor_d',
            'owner_occupied_near_fuel_poor_asset_poor_e',
            'owner_occupied_near_fuel_poor_asset_poor_f',
            'owner_occupied_near_fuel_poor_asset_poor_g'
        ],
        owner_occupied_near_fuel_poor_asset_rich: [
            'owner_occupied_near_fuel_poor_asset_rich_d',
            'owner_occupied_near_fuel_poor_asset_rich_e',
            'owner_occupied_near_fuel_poor_asset_rich_f',
            'owner_occupied_near_fuel_poor_asset_rich_g'
        ],
        owner_occupied_non_fuel_poor_asset_poor: [
            'owner_occupied_non_fuel_poor_asset_poor_a',
            'owner_occupied_non_fuel_poor_asset_poor_b',
            'owner_occupied_non_fuel_poor_asset_poor_c',
            'owner_occupied_non_fuel_poor_asset_poor_d',
            'owner_occupied_non_fuel_poor_asset_poor_e',
            'owner_occupied_non_fuel_poor_asset_poor_f',
            'owner_occupied_non_fuel_poor_asset_poor_g'
        ],
        owner_occupied_non_fuel_poor_asset_rich: [
            'owner_occupied_non_fuel_poor_asset_rich_a',
            'owner_occupied_non_fuel_poor_asset_rich_b',
            'owner_occupied_non_fuel_poor_asset_rich_c',
            'owner_occupied_non_fuel_poor_asset_rich_d',
            'owner_occupied_non_fuel_poor_asset_rich_e',
            'owner_occupied_non_fuel_poor_asset_rich_f',
            'owner_occupied_non_fuel_poor_asset_rich_g'
        ],
    }

    useEffect(() => {
        let newBranch = (parent) => {
            const nested = ['rental_social', 'rental_private', 'owner_occupied']
            setTreeBranches(prev => {
                prev[parent.name] = nested.includes(parent.name) ? 'nested' : 'active';
                return ({ ...prev });
            });
            if (parent.branches) {
                parent.branches.forEach(child => {
                    newBranch(child);
                });
            }
        };

        structure.interventionsList.forEach(intervention_group => {
            newBranch(intervention_group);
        });

    }, []);

    const treeHandler = (event) => {
        let id = event.currentTarget.id;
        let newState = treeBranches[id] === 'active' ? 'nested' : 'active';

        setTreeBranches(prev => {
            prev[id] = newState;
            return ({ ...prev });
        });
    };

    const numericHandler = (event) => {
        let value = event.target.value > 100 ? 100 : event.target.value;
        let update = data;

        govInterventions[event.target.id].forEach(input => {
            update.interventionsValues[input] = value;
        })
        update.interventionsInputs[event.target.id] = value;

        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
    };

    const deliveryHandler = (event) => {
        let value = event.target.value > 100 ? 100 : event.target.value;
        let update = data;
        update.deliveryYearsValues[event.target.id] = value;

        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
    };

    const fundingHandler = (event) => {
        let value = event.target.value > 100 ? 100 : event.target.value;
        let update = data;
        update.fundingYearsValues[event.target.id] = value;

        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
    };

    const commentHandler = (event, name) => {
        let value = event.target.value;
        let update = data;
        update.comments[name] = value;

        setData(prev => {
            prev = update;
            return ({ ...prev });
        });
    }

    const onBlur = () => {
        dataUpdates.processData(data, outputs, setOutputs);
    }

    return (
        <div>
            <ul id='interventions' className='flex-card'>
                {
                    (structure.interventionsList && data.interventionsValues) && structure.interventionsList.map(rental_group => {
                        return (
                            <li className='tree-branch' key={rental_group.name} name={rental_group.name}>
                                <div id={rental_group.name} onClick={treeHandler} className='tree-branch-name'>
                                    <FontAwesomeIcon data-id={rental_group.name} icon={faChevronRight} className='tree-branch-icon dark-blue-font' />
                                    <span className='text-large'>{rental_group.label}</span>
                                </div>
                                <ul className={treeBranches[rental_group.name]} >
                                    {
                                        rental_group.branches.map(intervention_group => {
                                            return (
                                                <li className='tree-branch' key={intervention_group.name}>
                                                    <div id={intervention_group.name} onClick={treeHandler} className='tree-branch-name'>
                                                        <FontAwesomeIcon icon={faChevronRight} className='tree-branch-icon dark-blue-font' />
                                                        <span>{intervention_group.label}</span>
                                                    </div>
                                                    {
                                                        intervention_group.branches ? (
                                                            <ul className={treeBranches[intervention_group.name]} > {
                                                                intervention_group.branches.map(asset_group => {
                                                                    return (
                                                                        <li className='tree-branch' key={asset_group.name}>
                                                                            <div id={asset_group.name} onClick={treeHandler} className='tree-branch-name'>
                                                                                <FontAwesomeIcon icon={faChevronRight} className='tree-branch-icon dark-blue-font' />
                                                                                <span>{asset_group.label}</span>
                                                                            </div>
                                                                            <div className={`tree-leaf ${treeBranches[asset_group.name]}`} >
                                                                                <div className='grid'>
                                                                                    {
                                                                                        asset_group.inputs.map(intervention => {
                                                                                            let value = intervention.inputType === 'gov_support'
                                                                                                ? data.interventionsInputs[intervention.name]
                                                                                                : intervention.inputType === 'delivery_years' ? data.deliveryYearsValues[intervention.name]
                                                                                                    : data.fundingYearsValues[intervention.name];
                                                                                            let handler = intervention.inputType === 'gov_support'
                                                                                                ? numericHandler
                                                                                                : intervention.inputType === 'delivery_years' ? deliveryHandler
                                                                                                    : fundingHandler;
                                                                                            return (
                                                                                                <NumberInput
                                                                                                    name={intervention.name}
                                                                                                    label={intervention.label}
                                                                                                    value={value}
                                                                                                    handler={handler}
                                                                                                    onBlur={onBlur}
                                                                                                    symbol={intervention.symbol}
                                                                                                    symbolPosition='end'
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className='grid'>
                                                                                    <TextArea value={data.comments[asset_group.name]} label="Complementary policies" onChange={(e) => { commentHandler(e, asset_group.name); }} />
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }</ul>
                                                        ) : (
                                                            <div className={`tree-leaf ${treeBranches[intervention_group.name]}`} >
                                                                <div className='grid'>
                                                                    {
                                                                        intervention_group.inputs.map(intervention => {
                                                                            let value = intervention.inputType === 'gov_support'
                                                                                ? data.interventionsInputs[intervention.name]
                                                                                : intervention.inputType === 'delivery_years' ? data.deliveryYearsValues[intervention.name]
                                                                                    : data.fundingYearsValues[intervention.name];
                                                                            let handler = intervention.inputType === 'gov_support'
                                                                                ? numericHandler
                                                                                : intervention.inputType === 'delivery_years' ? deliveryHandler
                                                                                    : fundingHandler;
                                                                            return (
                                                                                <NumberInput
                                                                                    name={intervention.name}
                                                                                    label={intervention.label}
                                                                                    value={value}
                                                                                    handler={handler}
                                                                                    onBlur={onBlur}
                                                                                    symbol={intervention.symbol}
                                                                                    symbolPosition='end'
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='grid'>
                                                                    <TextArea value={data.comments[intervention_group.name]} label="Complementary policies" onChange={(e) => { commentHandler(e, intervention_group.name); }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Tree