import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Tooltip } from 'react-tooltip';

const RadioGroupInput = (props) => {
  return (
    <FormControl key={props.key}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={props.value}
        onChange={props.handleChange}
      >
        {
          props.options.map(option => {
            return (
              <>
                <FormControlLabel
                  key={option.value} value={option.value} control={<Radio />} label={option.label}
                  data-tooltip-id={props.tooltip ? option.value : ''}
                  data-tooltip-content={props.tooltip ? props.tooltip : ''}
                  data-tooltip-place="bottom"
                />
                {props.tooltip && <Tooltip id={option.value} style={{ maxWidth: '350px', zIndex: '9999' }} />}
              </>
            )
          })
        }
      </RadioGroup>
    </FormControl>
  )
}

export default RadioGroupInput