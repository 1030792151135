import React from 'react'

const Separator = ({ edge }) => {
    return (
        <section className="separator wp-block separator--left is-inner-block-full-width is--inner-block">
            <div className="separator__inner">
                <span className={`separator__left ${edge === 'left' && 'separator__edge'}`}></span>
                <span className="separator__pointer"></span>
                <span className={`separator__right ${edge === 'right' && 'separator__edge'}`}></span>
            </div>
        </section>
    )
}

export default Separator;