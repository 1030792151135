import React, { useContext } from 'react';
import { DataContext } from '../../../context/DataContext';

const DownloadButton = () => {
    const [data] = useContext(DataContext);
    const downloadTxtFile = () => {
        const fileData = JSON.stringify(data);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "ukgbc_retrofit_calc_scenario.json";
        link.href = url;
        link.click();
    }

    return (
        <div className='flex-center'>
            <button onClick={downloadTxtFile}>Download scenario</button>
        </div>
    )
}

export default DownloadButton