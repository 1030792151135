import React, { useState, createContext } from 'react';

export const OutputContext = createContext();
export const OutputContextProvider = props => {
    const [outputs, setOutputs] = useState({});

    return (
        <OutputContext.Provider value={[outputs, setOutputs]}>
            {props.children}
        </OutputContext.Provider>
    )
}