import React, { useState, useRef } from 'react'
import './accordion.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Accordion = ({ title, component }) => {

    const content = useRef(null);
    const [setActive, setActiveState] = useState("accordion-hidden");
    const [chevron, setChevron] = useState(faChevronRight);

    const toggleAccordion = () => {
        setActiveState(setActive === "accordion-hidden" ? "accordion-active" : "accordion-hidden");
        setChevron(setActive === "accordion-active" ? faChevronRight : faChevronDown);
    }

    return (
        <div>
            <div className='accordion-section'>
                <button 
                    className={`accordion ${setActive === "accordion-active" ? 'accordion-open' : 'accordion-closed'}`}
                    onClick={toggleAccordion}
                >
                    <p className='accordion-title title-large dark-blue-font'>
                        {title}
                    </p>
                    <FontAwesomeIcon icon={chevron} className='accordion-icon' />
                </button>
                <div 
                    className={`accordion-content ${setActive === "accordion-active" ? 'accordion-active' : 'accordion-hidden'}`}
                    ref={content}
                >
                    <div className='accordion-body'>
                        {component}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion  