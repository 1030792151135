import React, { useContext } from 'react';
import Tree from './Common/Inputs/Tree/tree.component';
import NumberInput from './Common/Inputs/numeric.component';
import { DataContext } from '../context/DataContext';
import { StructureContext } from '../context/StructureContext';
import { dataUpdates } from '../utils/processData';
import { OutputContext } from '../context/OutputContext';

const Interventions = () => {
    const [data, setData] = useContext(DataContext);
    const [structure] = useContext(StructureContext);
    const [outputs, setOutputs] = useContext(OutputContext);

    const numericHandler = (event) => {
        let update = data;
        update.otherCostsValues[event.target.id] = event.target.value;
        setData(prev => {
            prev.otherCostsValues[event.target.id] = event.target.value;
            return ({ ...prev });
        });
    };

    const onBlur = () => {
        dataUpdates.processData(data, outputs, setOutputs);
    }

    // const currencyFormatter = new Intl.NumberFormat(window.navigator.language, {
    //     style: 'currency',
    //     currency: 'GBP',
    //     maximumFractionDigits: 0,
    // });

    // const FormattedInput = ({ value, setValue, minValue, maxValue }) => {
    //     return (
    //         <input
    //             type="text"
    //             value={currencyFormatter.format(value)}
    //             onChange={(e) => {
    //                 const nextValue = e.target.value.replace(/[^\d.]/g, '');
    //                 setValue(Math.min(maxValue, Math.max(minValue, parseFloat(nextValue === '' ? '0' : nextValue))));
    //             }}
    //         />
    //     )
    // }

    return (
        <div className="container-content flex-card">
            <div>
                <div className='title-medium dark-blue-font'>Financial support</div>
                <div>
                    You can amend the percentage <b>% of government funding</b> within each category and sub category to target the funding at specific household types, tenure types, and socio-economic groups  as appropriate. Set to 0% for no government funding. Eg Private Rented, Fuel Poor = 60% reflecting a government grant of 60% of the value of the measures.
                </div>
                <div>
                    The ‘type’ of grant is not considered, simply the proportion of the cost of the retrofit measures selected. The grant could be a householder voucher, a contractor subsidy, a subsidised interest rate etc. In the case of the later, a zero interest loan over 10 years may be equivalent to a 30% ‘grant’ and reflected in the calculator as 30% government funding.
                </div>
            </div>
            <div>
                <div className='title-medium dark-blue-font'>Duration</div>
                <div>
                    Set/update the <b>duration</b> of the funding and the overall delivery. Eg measures funded for the first 10 years with all completed in 20 years, meaning the last 10 years have no government support, just policies encouraging take up.
                </div>
                <div>
                    If different packages of measures need different durations such as: energy efficiency measures over one period, say 10 years, and low carbon heating  over another, say 25 years, then it is necessary to create two separate scenarios and combine the outputs manually.
                </div>
            </div>
            <div>
                <div className='title-medium dark-blue-font'>Complementary policies</div>
                <div>
                    Use the free text ‘complementary policies’ box to list the policies being assumed to encourage the co-investment from the homeowners, private or social landlords.
                </div>
            </div>
            <Tree />
            <div className='title-medium dark-blue-font'>Other policy costs (£bn)</div>
            <div className='grid'>
                {
                    (structure.otherCosts && data.otherCostsValues) && structure.otherCosts.map(cost => {
                        return (
                            <NumberInput
                                key={cost.name}
                                name={cost.name}
                                label={cost.label}
                                value={data.otherCostsValues[cost.name]}
                                handler={numericHandler}
                                onBlur={onBlur}
                                symbol={cost.symbol}
                                symbolPosition='start'
                            />
                        )
                    })
                }
            </div>
        </div>
    )
};

export default Interventions;