import React, { useState, useContext } from 'react';
import Modal from '@mui/material/Modal';

import SelectInput from './Common/Inputs/select.component';
import KPI from './Common/Outputs/kpi.component';
import { OutputContext } from '../context/OutputContext';
import { scenarios } from '../data/Scenarios';
import Separator from './Layout/separator.component';
import { DataContext } from '../context/DataContext';
import { dataUpdates } from '../utils/processData';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '20px',
};

const Summary = () => {
    let [scenario, setScenario] = useState('scenario_1');
    const [outputs, setOutputs] = useContext(OutputContext);
    const [data, setData] = useContext(DataContext);
    const [text, setText] = useState('intro');
    const [modal, setModal] = useState(false);
    const [file, setFile] = useState()
    const scenarioList = scenarios.scenarioList;

    const handleChange = event => {
        const selected = event.target.value;
        if (selected === 'custom') {
            setModal(true)
        } else {
            setScenario(selected);
            let newscenario = scenarios[selected];
            setData(prev => {
                prev = newscenario;
                return ({ ...prev })
            });
            dataUpdates.processData(newscenario, outputs, setOutputs);
        }
    };

    const fileUpload = (event) => {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = e => { setFile(JSON.parse(e.target.result)) };
    };

    const fileSubmit = (event) => {
        event.preventDefault();
        setScenario('custom');
        let newscenario = file;
        setData(prev => {
            prev = newscenario;
            return ({ ...prev })
        });
        dataUpdates.processData(newscenario, outputs, setOutputs);
        setModal(false);
    }

    const handleClose = () => setModal(false);

    return (
        <div className="flex-card gap-2">
            <div className="flex-card gap-2">
                <div className='display-medium dark-blue-font'>
                    Home Retrofit Investment Calculator
                </div>
                <div>
                    <ul className='summary-selector title-large'>
                        <li className={`summary-item title-large ${text === 'intro' ? 'summary-selected' : ''}`} onClick={() => setText('intro')}>Calculator</li>
                        <li className={`summary-item title-large ${text === 'guide' ? 'summary-selected' : ''}`} onClick={() => setText('guide')}>How-to</li>
                        <li className={`summary-item title-large ${text === 'about' ? 'summary-selected' : ''}`} onClick={() => setText('about')}>About</li>
                    </ul>
                    <Separator edge='left' />
                </div>
                <div className="flex-card summary-text-section" style={{ display: text === 'intro' ? 'flex' : 'none' }}>
                    <div>
                        How much should the UK Government invest to make upgrading the nation’s homes affordable and attractive?
                    </div>
                    <div>
                        Working with industry experts and academics, the UK Green Building Council has a created this calculator to open the books so you can ‘play’ Chancellor and Secretary of State.
                    </div>
                    <div>
                        Use the calculator to work out, as Chancellor, how much you would invest over what time frame. Who would you prioritise – people living social housing or privately owned homes? Fuel-poor residents or asset-rich owner occupiers? Which retrofit measures would you prioritise? How will your choices impact on energy bills, returns to the Treasury, public health, and climate emissions? As Secretary of State, what policies and regulations would you introduce to encourage private investment and shift markets?
                    </div>
                    <div>
                        The calculator has been developed to allow an evidence-based debate on the magnitude of investment and policies needed and to help us all assess policy decisions and priorities.
                    </div>
                </div>
                <div className="flex-card summary-text-section" style={{ display: text === 'about' ? 'flex' : 'none' }}>
                    <div className='heading-medium dark-blue-font'>
                        Who is behind the calculator?
                    </div>
                    <div>
                        The calculator has been developed by an expert team from Leeds Beckett University, Thermly , Arup and PwC with guidance and review from a wide range of experts and industry leaders. Thermly have turned the calculator into a user-friendly web-based tool.
                    </div>
                    <div>
                        The project has been made possible by the financial and intellectual support of UKGBC members including UKGBC’s Advancing Net Zero Programme Partners: Bam, Berkeley Group, Buro Happold, CBRE, Grosvenor, Hoare Lea, ISG, JLL, Lloyds Banking Group, Rockwool, Turner & Townsend. And seven Project Partners: Clarion Housing Group, E.ON, the Greater London Authority, Arup, HTA, Lendology cic, MCS Foundation, and SE2. The project has also been generously supported by #BuildingLife2 funded by the Ikea and Laudes Foundations. We are grateful for their leadership and commitment to upgrading our nation of homes. More information about the project is here [3].
                    </div>
                </div>
                <div className="flex-card summary-text-section" style={{ display: text === 'guide' ? 'flex' : 'none' }}>
                    <div className='heading-medium dark-blue-font'>
                        Step by step guide
                    </div>
                    <ol type="1" className='flex-card'>
                        <li><b>Quick start.</b> Select a starting point from the 4 template scenarios – this will pre-populate the tool so you don’t have to start from scratch, but allows you to make adjustments if you wish. </li>
                        <li><b>Home retrofit ambition.</b> For each major retrofit measure, you can use the sliders to adjust your level of ambition if you wish i.e the percentage of suitable roofs or walls that should have solar panels or insulation</li>
                        <li><b>Government support and policies for households.</b> You can select the level of government grant support for each type of household, the duration of the funding and the overall duration of your national retrofit programme. Use the box to note your complementary policies to drive co-investment by the homeowner and others.</li>
                        <li><b>Government investment in enablers.</b> Input your grant support for the strategic enabling functions such as training, local authority capacity, R&D etc for the full duration of your national retrofit programme.</li>
                        <li><b>Geography.</b> Select specific nations or the whole UK</li>
                        <li><b>Review your decisions.</b>Review the scale of your investments and their outcomes and refine your decisions as needed.</li>
                    </ol>
                    <div className='heading-medium dark-blue-font'>
                        What is the calculator?
                    </div>
                    {/* <ul className='summary-about-list flex-card'>
                        <li><b>Choose your level of retrofit ambition.</b> For example, what percentage of homes that are suitable for cavity wall insulation would you aim to treat? What percentage of suitable roofs should have solar panels?</li>
                        <li><b>As ‘Chancellor’, choose how to make retrofit affordable and fair.</b> What percentage of the cost of the retrofit would you support for different household groups, for example landlords with fuel poor tenants?</li>
                        <li><b>As ‘Secretary of State’, decide what policies you would introduce.</b> If you decide on 20% grant funding in a particular situation, what policies or reasonable assumptions will encourage the other 80% of investment from homeowners, private or social landlords or other investors?</li>
                        <li><b>As ‘Chancellor’, how much would you invest in the crucial enablers?</b> What is your priority - training, local authority capacity building and information campaigns etc?</li>
                        <li>Select results for each nation or the whole of the UK</li>
                    </ul> */}
                    <div>
                        The calculator is a unique tool built on a huge database which brings together the latest information on the state of the UK housing stock and the cost of retrofit measures with the economic, health and environmental benefits of different types of investment.
                    </div>
                    <div>
                        This is the first tool of its kind available for public use. It is designed to show the broad implications of different decisions and the magnitude of investment needed in order to propel a national conversation and political commitment.
                    </div>
                    <div>
                        The calculator is a modelling tool, which means it has been simplified to focus on allowing a range of scenarios and taking the best possible evidence without offering a vast number of choices that would make it bewildering. So, there are limits to how much nuance the tool can reflect in policy design, or how specifically it can optimise a particular approach.
                    </div>
                    <div>
                        The range of baseline assumptions we have used are available here [1] More information on the data behind the calculator is here [2].
                    </div>
                </div>
            </div>
            <div className="flex-card">
                <div className='heading-medium dark-blue-font'>
                    Quick start scenario
                </div>
                <div>
                    Select from one of four quick start template scenarios to pre-populate the calculator. Each quick start scenarios provides a different starting point: only focusing on energy efficiency, only low carbon heating, a combination of energy efficiency and low carbon heating, all values zeroed. There is also the option to upload a scenario you have previously saved (on your computer) or received from others.
                </div>
                <div>
                    Using these quick start scenarios you can then make your own adjustments and save these as appropriate. Details are here [4]

                </div>
                <form>
                    <SelectInput label="Select base scenario" name="scenario" value={scenario} options={scenarioList} handler={handleChange} />
                    <Modal
                        open={modal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div style={style} className='flex-card'>
                            <div className='title-medium dark-blue-font'>
                                Upload a scenario
                            </div>
                            <div>
                                Upload a scenario which you have previously created and downloaded.
                            </div>
                            <form onSubmit={fileSubmit}>
                                <h1>React File Upload</h1>
                                <input type="file" onChange={fileUpload} />
                                <button type="submit">Upload</button>
                            </form>
                        </div>
                    </Modal>
                </form>
            </div>
            {
                outputs.kpis && outputs.costs && <div className="grid justify-center">
                    <KPI label='Total government contribution' value={'£' + Math.round(Number(outputs.kpis.gov_spend_year_10) / 1000000000)} metric={'bn'} 
                                tooltipId='gov_contr'
                                tooltip='Grant funding provided by government.'/>
                    <KPI label='Net government return' value={'£' + Math.round(-(Number(outputs.kpis.gov_spend_year_10) - Number(outputs.costs.tax_raised_year_10)) / 1000000000)} metric={'bn'}  
                                tooltipId='gov_return'
                                tooltip='Illustration of the potential government ‘benefit’ after grant expenditure is deducted from tax raised, assuming a tax to stimulus ratio of 0.49. A negative is a net government ‘cost’.'/>
                    <KPI label='Winter day increased elec demand' value={Math.round(Number(outputs.kpis.winter_day_year_10) / 1000000000)} metric={'TWh'}
                                tooltipId='wint_demand'
                                tooltip='Winter day increased electricity (non-fossil fuel) demand compared with the counterfactual (see below).
                                A negative number indicates demand is reduced compared with the counterfactual.
                                Counterfactual – replacing fossil fuel heating with heat pumps (90%) and other non fossil fuel heating (10%) with no investment in energy efficiency and a heat pump SCOP of 2.5.' />
                    <KPI label='Illustrative generation investment' value={'£' + Math.round(Number(outputs.kpis.capacity_generation_year_10) / 1000000000)} metric={'bn'} 
                                tooltipId='gen_invest'
                                tooltip='The additional generation capacity investment to accommodate the change in winter load compared with a counterfactual (excludes additional grid reinforcement costs).
                                If a negative number, then investment is reduced compared with the counterfactual.
                                Counterfactual – replacing fossil fuel heating with heat pumps (90%) and other non fossil fuel heating (10%) with no investment in energy efficiency and a heat pump SCOP of 2.5.'/>
                </div>
            }
        </div>
    )
}

export default Summary;