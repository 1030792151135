import React, { useContext } from 'react';
import './header.style.css';
import Logo from "../../images/ukgbc_logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faPlugCircleBolt, faSnowflake, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { OutputContext } from '../../context/OutputContext';
import { Tooltip } from 'react-tooltip';

const Header = () => {
	const [outputs] = useContext(OutputContext);
	return (
		<header className="app-header">
			<div class="container-wide align-center flex-row justify-space-between">
				<img src={Logo} alt="UKGBC logo" className='logo' />
				<div className="header-kpis">
					{
						outputs.kpis && outputs.costs && <ul className='header-kpis'>
							<li className='header-kpis-item text-large'
								data-tooltip-id="header-gov-spend"
								data-tooltip-content="Government investment - Grant funding provided by government."
								data-tooltip-place="bottom"
							>
								<FontAwesomeIcon icon={faCoins} data-tip data-for="toolsTip" className='nav-icon title-medium' />
								{'£' + Math.round(Number(outputs.kpis.gov_spend_year_10) / 1000000000)}
								<span className='text-extra-small'>bn</span>
								<Tooltip id="header-gov-spend" style={{ maxWidth: '350px', zIndex: '9999', fontSize: '14px'}} />
							</li>
							<li className='header-kpis-item text-large'
								data-tooltip-id="header-gov-return"
								data-tooltip-content="Net government return - Illustration of the potential government ‘benefit’ after grant expenditure is deducted from tax raised, assuming a tax to stimulus ratio of 0.49. A negative is a net government ‘cost’."
								data-tooltip-place="bottom"
							>
								<FontAwesomeIcon icon={faLandmark} data-tip data-for="costTip" className='nav-icon title-medium' />
								{'£' + Math.round(-(Number(outputs.kpis.gov_spend_year_10) - Number(outputs.costs.tax_raised_year_10)) / 1000000000)}
								<span className='text-extra-small'>bn</span>
								<Tooltip id="header-gov-return" style={{ maxWidth: '350px', zIndex: '9999', fontSize: '14px'}} />
							</li>
							<li className='header-kpis-item text-large'
								data-tooltip-id="header-winter-elec"
								data-tooltip-content="Winter day elec demand - Winter day increased electricity (non-fossil fuel) demand compared with the counterfactual (see below).
								A negative number indicates demand is reduced compared with the counterfactual.
								Counterfactual – replacing fossil fuel heating with heat pumps (90%) and other non fossil fuel heating (10%) with no investment in energy efficiency and a heat pump SCOP of 2.5."
								data-tooltip-place="bottom"
							>
								<FontAwesomeIcon icon={faSnowflake} data-tip data-for="savingTip" className='nav-icon title-medium' />
								{Math.round(Number(outputs.kpis.winter_day_year_10) / 1000000000)}
								<span className='text-extra-small'>TWh</span>
								<Tooltip id="header-winter-elec" style={{ maxWidth: '350px', zIndex: '9999', fontSize: '14px'}} />
							</li>
							<li className='header-kpis-item text-large'
								data-tooltip-id="header-cap-gen"
								data-tooltip-content="Illustrative generation investment - The additional generation capacity investment to accommodate the change in winter load compared with a counterfactual (excludes additional grid reinforcement costs).
								If a negative number, then investment is reduced compared with the counterfactual.
								Counterfactual – replacing fossil fuel heating with heat pumps (90%) and other non fossil fuel heating (10%) with no investment in energy efficiency and a heat pump SCOP of 2.5."
								data-tooltip-place="bottom"
							>
								<FontAwesomeIcon icon={faPlugCircleBolt} data-tip data-for="savingTip" className='nav-icon title-medium' />
								{'£' + Math.round(Number(outputs.kpis.capacity_generation_year_10) / 1000000000)}
								<span className='text-extra-small'>bn</span>
								<Tooltip id="header-cap-gen" style={{ maxWidth: '350px', zIndex: '9999', fontSize: '14px'}} />
							</li>
						</ul>
					}
				</div>
			</div>
		</header>
	)
}

export default Header