const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
});

const formatNumber = (num, type) => {
    let mill = false;
    if(num > 999999) {
        num = num/1000000;
        mill = true;
    };
    if(type === 'currency') num = formatter.format(num);
    else num = num.toLocaleString("en", {minimumFractionDigits: 0, maximumFractionDigits: 2,});
    let sub = mill ? 'm' : '';
    return num + sub;
};

export default formatNumber;