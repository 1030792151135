let serverUrl = process.env.REACT_APP_SERVER_URL_DEV;

const getUser = async (domain, user, accessToken) => {
    
    const response = await fetch(
        `${domain}/${user}`, 
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    const user_metadata = await response.json();
    return user_metadata;
}

const getAll = async (resource) => {
    const response = await fetch(
        `${serverUrl}/${resource}`
    );
    const data = await response.json();
    return data;
};

const getSingle = async (resource, id, accessToken) => {
    const response = await fetch(
        `${serverUrl}/${resource}/${id}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    const data = await response.json();
    return data;
};

const post = async (resource, id, object) => {
    const response = await fetch(
        `${serverUrl}/${resource}/${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        }
    );
    const data = await response.json();
    return data;
};

export const apiProvider = {
    getUser,
    getAll,
    getSingle,
    post
}