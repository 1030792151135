export const Bam = require('./bam.png');
export const Berkeley = require('./berkeley.png');
export const Buro = require('./buro.png');
export const Cbre = require('./cbre.png');
export const Grovsenor = require('./grosvenor.png');
export const Hoare = require('./hoare.png');
export const Isg = require('./isg.png');
export const Jll = require('./jll.png');
export const Lloyds = require('./lloyds.png');
export const Rockwool = require('./rockwool.png');
export const Turner = require('./turner.png');