import React from 'react';

const CheckGroupInput = (props) => {
    let { values, labels, handler } = props;
    return (
        <fieldset>
            <legend>Select countries to target</legend>
            {
                Object.keys(values).map(country => {
                    return (
                        <div key={country} >
                            <input type="checkbox" checked={values[country]} name={country} onChange={handler} />
                            <label htmlFor="html">{labels[country]}</label><br />
                        </div>
                    )
                })
            }
        </fieldset>
    )
};

export default CheckGroupInput;