import React from 'react';
import './numeric.style.css';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const NumberInput = (props) => {
    const { name, label, value, handler, onBlur, symbol, symbolPosition } = props;

    const inputProps = () => {
        let InputProps = {}
        if(symbolPosition === 'end') InputProps = {
                endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
            }
        else InputProps = {
                startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
            }
        return InputProps
    }

    return (
        <TextField
            key={name}
            type='number'
            label={label}
            id={name}
            value={value}
            onChange={handler}
            onBlur={onBlur}
            InputProps={inputProps()}
        />
    )
}

export default NumberInput